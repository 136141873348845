import { observer } from 'mobx-react';

import './styles.scss';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import {
    DATE_YMD,
    FILTER_INFORMATION_MAPPER,
    INFORMATION_TYPE,
    INFORMATION_TYPE_MAPPER,
    READ_INFORMATION,
    READ_INFORMATION_MAPPER, 
    SYSTEM_PATH
} from '../../core/configs/constants';
import { useStore } from '../../core/utils/hook';
import { flowResult } from 'mobx';
import Pagination from '../Courses/components/Pagination';
import moment from 'moment';

const InformationScreen = observer(() => {
    // store
    const { informationStore: { lstInformation, getAllInformationsForStudent, pagingInformation } } = useStore();

    // state
    const [typeInformation, setTypeInformation] = useState('');
    const [typeReadInformation, setTypeReadInformation] = useState('');
    const [typeFilter, setTypeFilter] = useState('NEWEST');
    const [statusHideFilterTypeInformation, setStatusHideFilterTypeInformation] = useState({
        [INFORMATION_TYPE.NEWS]: false,
        [INFORMATION_TYPE.DRUG_INFOR]: false,
        [INFORMATION_TYPE.GUIDELINE_INFOR]: false,
        [INFORMATION_TYPE.OTHERS]: false
    })

    // lifecycle
    useEffect(() => {
        onFetchData({
            ...(Object.values(INFORMATION_TYPE).includes(typeInformation) ? { type: typeInformation } : {}),
            ...(typeReadInformation ? { isRead: typeReadInformation } : {}),
            ...(typeFilter !== 'NEWEST' ? { 
                sortKey: FILTER_INFORMATION_MAPPER[typeFilter].SORT_KEY,
                sortDir: FILTER_INFORMATION_MAPPER[typeFilter].SORT_DIR
            } : {})
        });
    }, [typeInformation, typeReadInformation, typeFilter]);

    useEffect(() => {
        if (lstInformation?.length > 0 && typeInformation === '') {
            setStatusHideFilterTypeInformation(prev => {
                const newStatus = { ...prev };
                Object.values(INFORMATION_TYPE).forEach(type => {
                    const informationByType = lstInformation.filter(information => information.type === type);
                    if (informationByType?.length <= 0) {
                        newStatus[type] = true;
                    } else {
                        newStatus[type] = false;
                    }
                });
                return newStatus;
            });
        }
    }, [lstInformation, typeInformation]);

    // function
    const onFetchData = (payload) => {
        getAllInformationsForStudent({...pagingInformation, ...payload}, true);
    }

    const onFilterReadChange = (e) => {
        setTypeReadInformation(e.target.value);
    }

    const onFilterNewestChange = (e) => {
        setTypeFilter(e.target.value);
    }

    return (
        <div
            id="main"
            className="wrapper main-account-trgisstration-1 main-account-trgisstration-2 main-account-trgisstration-4 main-update main-send-inquiry"
        >
            <div className='information-screen container'>
                <div className='title'>Pharma Info</div>
                <div className='list-container'>
                    <div className='filter d-flex align-items-center justify-content-between'>
                        <div className='filter-sidebar d-flex'>
                            <FilterItem title='すべてのカテゴリー' onClickFilterItem={() => setTypeInformation('')} isActive={typeInformation === ''} />
                            {
                                Object.values(INFORMATION_TYPE).map((type, index) => {
                                    if (!statusHideFilterTypeInformation[type]) {
                                        return (
                                            <FilterItem 
                                                key={index}
                                                title={INFORMATION_TYPE_MAPPER[type].LABEL}
                                                onClickFilterItem={() => setTypeInformation(type)}
                                                isActive={type === typeInformation} />
                                        )
                                    }
                                    return null;
                                })
                            }
                        </div>

                        <div className='filter-select d-flex align-items-center justify-content-center flex-gap-10'>
                            <select className='filter-select-item' onChange={onFilterReadChange}>
                                <option value=''>すべてのステータス</option>
                                {
                                    Object.values(READ_INFORMATION).map((status, index) => (
                                        <option key={index} value={status}>{READ_INFORMATION_MAPPER[status].LABEL}</option>
                                    ))
                                }
                            </select>
                            <select className='filter-select-item' onChange={onFilterNewestChange}>
                                {
                                    Object.keys(FILTER_INFORMATION_MAPPER).map((item, index) => (
                                        <option key={index} value={item}>{FILTER_INFORMATION_MAPPER[item].LABEL}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                    <div className='content'>
                        {
                            lstInformation && lstInformation?.length > 0 ?
                                lstInformation.map((information, index) => (
                                    <GridInformationItem
                                        key={index}
                                        id={information.id}
                                        type={information.type}
                                        title={information.title}
                                        publicDate={information.publicDate}
                                        isRead={information.isRead} />
                                )) : <div className='text-center mg-t-20'>記事がありません。</div>
                        }
                    </div>
                </div>
                <Pagination paging={pagingInformation} onFetch={onFetchData} />
            </div>
        </div>
    )
});

export default InformationScreen;

const FilterItem = ({ title, onClickFilterItem, isActive }) => {
    return (
        <div
            className={classNames('filter-item', isActive && 'filter-item-active')}
            onClick={() => onClickFilterItem()}
        >
            {title}
        </div>
    )
}

const GridInformationItem = ({ id, type, title, publicDate, isRead }) => {
    // store
    const { informationStore: { viewInformation } } = useStore();

    // function
    const handleNavigateDetail = async () => {
        const res =  await flowResult(viewInformation(id, {}));
        if (res) {
            window.open(`${SYSTEM_PATH.INFORMATION_SCREEN}/${id}`,'_blank')
        }
    }

    return (
        <div className='grid-item cursor-pointer' onClick={handleNavigateDetail}>
            <div className='d-flex align-items-center flex-gap-5'>
                <div 
                    style={{ backgroundColor: INFORMATION_TYPE_MAPPER[type].BG_ICON }}
                    className='icon-bg d-flex justify-content-center align-items-center pd-5'>
                    {
                        type === INFORMATION_TYPE.GUIDELINE_INFOR ? (
                            <i className='d-flex justify-content-center align-items-center fa-solid fa-book-open fa-xs'
                                style={{ color: '#2f7809', width: '15px', height: '12px' }}></i>
                        ) : (
                            <img src={INFORMATION_TYPE_MAPPER[type].ICON} style={{ color: `${INFORMATION_TYPE_MAPPER[type].COLORCLASS}` }} />
                        )
                    }
                </div>
                <div style={{ color: INFORMATION_TYPE_MAPPER[type].COLORCLASS, fontSize: '12px' }}>{INFORMATION_TYPE_MAPPER[type].LABEL}</div>
            </div>
            <div className={classNames('title mg-t-5 line-clamp-2', !isRead && 'fw-bold')}>{title}</div>
            <div className='public-date mg-t-15 d-flex align-items-center justify-content-between'>
                <div>{publicDate ? moment(publicDate).format(DATE_YMD) : ''}</div>
                {
                    isRead && (
                        <div className='d-flex align-items-center'>
                            <i className='fa-solid fa-check'></i>
                            <span>既読</span>
                        </div>
                    )
                }
            </div>
        </div>
    )
}