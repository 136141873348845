import ApiService from './ApiService';

class UserApi extends ApiService {

    constructor() {
        super();
    }

    getUserInfoHeader(payload) {
        return this.get('info', payload);
    }

    getCoursesOfUser(payload) {
        return this.get('course-of-user', payload);
    }

    getProfile() {
        return this.get();
    }

    updateProfile(payload) {
        return this.put('', payload);
    }

    getPurchaseHistories(payload) {
        return this.get('purchase-history', payload);
    }

    addAttendanceCode(payload) {
        return this.post('company-attendance/attendance', payload);
    }

    validateAddAttendanceCode(payload) {
        return this.post('company-attendance/attendance-validate', payload);
    }

    changePassword(payload) {
        return this.put('password', payload);
    }
    
    deletePurchase({ id }) {
        return this.delete(`purchase/${id}`, {});
    }

    cancelPurchase({ id }) {
        return this.put(`cancel-purchase/${id}`, {});
    }

    getToolCertificate(id) {
        return this.get(`tool-certificate/${id}`, {})
    }

    getSettingToolCertificate({ id, payload }) {
        return this.get(`setting-tool-certificate/${id}`, payload)
    }

    saveToolCertificate({ id, payload }) {
        return this.post(`tool-certificate/${id}`, payload)
    }

    resetToolCertificate(id) {
        return this.post(`reset-tool-certificate/${id}`, {})
    }

    cancelAttendance(courseId) {
        return this.delete(`cancel-attendance/${courseId}`, {})
    }
}

export default UserApi;