import moment from 'moment';
import { convertVideoDuration, extractContent, truncateByWords } from '../../../core/utils/common';
import { useNavigate } from 'react-router-dom';
import { useStore } from '../../../core/utils/hook';
// eslint-disable-next-line max-len
import { MSG, LECTURE_STATUS_OF_COURSE_MESSAGE, DATE_YMD, COURSE_STATUS_COMPLETE, COURSE_PERIOD_STATUS, DATE_MD_JP } from '../../../core/configs/constants';
import { toJS } from 'mobx';
import classNames from 'classnames';
import { useMemo } from 'react';
import { isShowCourseStatus, isViewOldCourse } from '../ultis/courses.ultis';

const LectureCard = (props) => {

    const { lecture } = props;
    const navigate = useNavigate();
    const {
        userStore: { userInfoHeader, coursePeriodStatus, removeUserCourseByCoursePeriodExpireDate },
        authStore: { token, userInfo },
        modalStore: { hide, openAlert },
        courseStore: { lectureByCourse, coursDetail }
    } = useStore();

    const description = extractContent(lecture?.content);

    const handleViewLectureDetail = () => {
        if (token) {
            navigate(`/lecture/${lecture?.id}`);
        } else {
            openAlert(MSG['warning.student.view.lecture.detail'], () => {
                hide();
            })
        }
    }

    const betweenoPeriodDate = () => {
        return userInfoHeader?.coursePeriodStart && userInfoHeader?.coursePeriodEnd && 
        moment().isBetween(moment(userInfoHeader.coursePeriodStart), moment(userInfoHeader.coursePeriodEnd), 'date', '[]')
    }

    const lectureRes = useMemo(() => {
        const lectureDetail = toJS(lecture);
        const lastTimeViewed = lectureDetail?.last_time_viewed || 0;
        const videoLength = lectureDetail?.video_length || 0;
        let name = '未視聴';
        let color = '#6D757D';

        if (lastTimeViewed > 0 && lastTimeViewed < videoLength) {
            name = '視聴中';
            color = '#FFC108'
        }

        if (lastTimeViewed > 0 && lastTimeViewed === videoLength || lectureDetail?.status === 2) {
            name = '視聴済';
            color = '#28A745'
        }


        return { name, color };
    }, [lecture])

    const renderMessage = () => {
        if (!token) {
            return (<div style={{ textAlign: 'end', color: 'red' }}>{LECTURE_STATUS_OF_COURSE_MESSAGE.USER_NOT_LOGIN}</div>)
        }

        if (coursePeriodStatus == COURSE_PERIOD_STATUS.EXPIRED) {
            return (<div style={{ textAlign: 'end', color: 'red' }}>{LECTURE_STATUS_OF_COURSE_MESSAGE.USER_LECTURE_EXPIRED}</div>);
        }

        if ((lectureByCourse?.allowViewLecture == true && betweenoPeriodDate()) ||
            isViewOldCourse(token, coursDetail)) {
            return (
                <div onClick={handleViewLectureDetail} className="read-more-2">受講画面へ<i className="fa fa-long-arrow-right"
                    aria-hidden="true"></i></div>
            )
        }

        if (lectureByCourse?.allowViewLecture == true && !betweenoPeriodDate()) {
            return (
                <div style={{ textAlign: 'end', color: 'red' }}>{LECTURE_STATUS_OF_COURSE_MESSAGE.USER_LECTURE_ATTENDING}</div>
            )
        }

        if(coursDetail && userInfoHeader) {
            if(userInfoHeader.isCourseTicket && !userInfoHeader.isFreeTicket && !coursDetail.isSpecifiedCourse) {
                return <div style={{ textAlign: 'end', color: 'red' }}>
                    {LECTURE_STATUS_OF_COURSE_MESSAGE.COURSE_NOT_IN_TICKET_PLAN}
                </div>
            }

            if(coursDetail.isFreeCourse && 
                userInfoHeader.isCourseTicket && 
                userInfoHeader.isFreeTicket && 
                !userInfoHeader.remainingFreeAmountTicket
            ) {
                return <div style={{ textAlign: 'end', color: 'red' }}>
                    {LECTURE_STATUS_OF_COURSE_MESSAGE.NOT_ENOUGH_FREE_TICKET}
                </div>
            }
        }
        
        return (
            <div style={{ textAlign: 'end', color: 'red' }}>
                {LECTURE_STATUS_OF_COURSE_MESSAGE.USER_LECTURE_NOT_ATTENDED}
            </div>
        )
    }

    const renderMessageTwo = () => {

        if (coursePeriodStatus == COURSE_PERIOD_STATUS.EXPIRED && 
                coursDetail?.statusUserCourse !== COURSE_STATUS_COMPLETE.USER_COURSE_APPLY_CERTIFICATE &&
                removeUserCourseByCoursePeriodExpireDate &&
                moment().isBefore(moment(removeUserCourseByCoursePeriodExpireDate))) {
            return (
                <div style={{ textAlign: 'end', color: 'red', marginTop: '5px' }}>{
                    moment(removeUserCourseByCoursePeriodExpireDate).format(DATE_MD_JP)}までに受講プランをお申込みの場合、受講中で終了した講座の受講履歴から開始することができます。
                </div>
            );
        }

        return <></>
    }

    return (
        <div className="item lt-theme-item">
            <div className="img">
                <a ><img src={lecture?.thumbnail || '/images/img-1.png'} alt="" /></a>
            </div>
            
            <div className="nav-img">
                <h3 className={classNames('title-3', lecture?.is_new && 'icon-lecture-new')}>
                    {
                        lectureByCourse?.allowViewLecture == true && 
                        betweenoPeriodDate() && 
                        coursePeriodStatus !== COURSE_PERIOD_STATUS.EXPIRED ?
                            <a style={{ cursor: 'pointer' }} onClick={handleViewLectureDetail}>{lecture?.name}</a> :
                            <a>{lecture?.name}</a>
                    }
                </h3>
                { isShowCourseStatus(token, coursDetail) && 
                    <span className='item-course-status' style={{ background: lectureRes.color }}>{lectureRes.name}</span>
                }
                <div className="clock-video">
                    <ul>
                        <li>
                            <b>公開日:</b>{lecture?.public_date ? moment(lecture?.public_date).format(DATE_YMD) : ''}
                        </li>
                        <li><b>動画時間:</b> 約 {`${convertVideoDuration(lecture?.video_length || 0)} 分`}</li>

                    </ul>

                </div>
                <p className="dessc-2">
                    {truncateByWords(description)?.text}
                </p>
                {/* 2 = GA*/}
                {userInfo && userInfo?.roleId !== 2 && renderMessage()}
                {userInfo && userInfo?.roleId !== 2 && renderMessageTwo()}
            </div>
            <div className="clearfix"></div>
        </div>
    )
}

export default LectureCard;