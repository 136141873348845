import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useStore } from '../../core/utils/hook';
import { useForm } from 'react-hook-form';
import yup from '../../core/utils/yupValidate';
import { yupResolver } from '@hookform/resolvers/yup';
import { LOGIN_TYPE, SYSTEM_PATH } from '../../core/configs/constants';
import { Link, useNavigate } from 'react-router-dom';
import classNames from 'classnames';

const LoginScreen = observer(() => {

    // other
    const navigate = useNavigate();

    // store
    const { authStore: { token, login } } = useStore();

    // state
    const [ showPassword, setShowPassword ] = useState(false);
    const [ loginType, setLoginType ] = useState(LOGIN_TYPE.STUDENT);

    const validateLoginSchema = yup.object().shape({
        email: yup.string().required('メールアドレスを入力する必要があります。'),
        password: yup.string().required('パスワードを入力する必要があります。'),
        remember: yup.boolean()
    })

    const defaultValues = { remember: true };

    const { register, handleSubmit, 
        formState: { errors, isSubmitting } } = useForm({resolver: yupResolver(validateLoginSchema), mode: 'onChange', defaultValues});

    // lifecycle
    // useEffect(() => {
    //     if(token) navigate(SYSTEM_PATH.HOME);
    // }, [token])

    // function
    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    }

    const onLogin = async (data) => {
        const { remember, ...payload } = data;
        const res = await login({
            ...payload,
            role: loginType
        }, remember);
        if(res) {
            sessionStorage.setItem('isGetMessagePopup', true);
            navigate(0);
        }
    }

    const onChangeLoginType = (loginType) => {
        setLoginType(loginType);
    }

    return(
        <div id='main' className='wrapper main-signin'>
            <div className='bres-cus'>
                <div className='container'>
                    <ul>
                        <li>
                            <Link to={SYSTEM_PATH.HOME}><img src='/images/icon-home.svg' alt='' /></Link> 
                        </li>
                        <li className='dash'>\</li>
                        <li>ログイン</li>
                    </ul>
                </div>
            </div>
            <div className='content-signin'>
                <div className='container'>
                    <div className='content-form'>
                        <h2 className='title-primary'>ログイン</h2>
                        <form onSubmit={handleSubmit(onLogin)}>
                            <div className='change-role-tab'>
                                <div 
                                    onClick={loginType === LOGIN_TYPE.GROUP_ADMIN ? () => onChangeLoginType(LOGIN_TYPE.STUDENT) : () => {}}
                                    className={
                                        classNames(
                                            'd-flex align-items-center justify-content-center flex-gap-5 w-50 cursor-pointer',
                                            loginType === LOGIN_TYPE.STUDENT && 'active')}>
                                    <i className='fa-solid fa-user'></i>
                                    <span>受講者</span>
                                </div>
                                <div
                                    onClick={loginType === LOGIN_TYPE.STUDENT ? () => onChangeLoginType(LOGIN_TYPE.GROUP_ADMIN) : () => {}}
                                    className={
                                        classNames(
                                            'd-flex align-items-center justify-content-center flex-gap-5 w-50 cursor-pointer',
                                            loginType === LOGIN_TYPE.GROUP_ADMIN && 'active')}>
                                    <i className='fa-solid fa-users-line'></i>
                                    <span>団体管理者</span>
                                </div>
                            </div>
                            <div className='item item-1'>
                                <label htmlFor>メールアドレス <span>*</span></label>
                                <input type='text' {...register('email')}/>
                                {
                                    errors?.email &&
                                    <div className='text-danger mg-t-5'>{errors.email?.message}</div>
                                }
                            </div>
                            <div className='item'>
                                <label htmlFor>パスワード <span>*</span></label>
                                <div className='input'>
                                    <input type={!showPassword ? 'password' : 'text'} {...register('password')} 
                                        onCopy={(e)=> e.preventDefault()} 
                                        // onPaste={(e)=> e.preventDefault()}
                                        onCut={(e)=> e.preventDefault()}/>
                                    <button type='button' className='button-toggle-password' onClick={toggleShowPassword}>
                                        {
                                            !showPassword ?
                                                <i className='fa fa-eye-slash'></i>
                                                : 
                                                <i className='fa fa-eye'></i>
                                        }
                                    </button>
                                </div>
                                {
                                    errors?.password &&
                                    <div className='text-danger mg-t-5'>{errors.password?.message}</div>
                                }
                            </div>
                            <div className='item item-checkbox justify-content-between flex-wrap flex-gap-10'>
                                <span>
                                    <input type='checkbox' {...register('remember')}/>ログイン情報保存
                                </span>
                                <p className='note mg-tb-0'>
                                    <span onClick={() => navigate(SYSTEM_PATH.FORGOT_PASSWORD,
                                        { state: { loginType } })} className='hover-link'>パスワードを忘れた方はこちら </span>
                                </p>
                            </div>
                            

                            <input type='submit' value='ログイン'/>
                            {
                                loginType === LOGIN_TYPE.STUDENT && (
                                    <p className='note'>
                                        <span onClick={() => navigate(SYSTEM_PATH.REGISTRATION_FIRST_STEP)} className='hover-link'>
                                            新規登録がお済みでない方はこちら</span>
                                    </p>
                                )
                            }
                            <p className={classNames(
                                'note mg-t-0',
                                loginType === LOGIN_TYPE.GROUP_ADMIN && 'mg-t-30')} onClick={() => navigate(SYSTEM_PATH.FAQ_SCREEN)}>
                                {/* <i className='fa fa-question-circle' aria-hidden='true' /> */}
                                <span className='hover-link'>ログインできない方はこちら</span>
                            </p>
                            {
                                loginType === LOGIN_TYPE.GROUP_ADMIN && (
                                    <p style={{ height: '22px' }}></p>
                                )
                            }
                        </form>
                    </div>
                </div>
            </div>
        </div>

    )
})

export default LoginScreen;