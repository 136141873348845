/* eslint-disable no-undef */
import React from 'react';
import videojs from 'video.js';
import PropTypes, { func } from 'prop-types';
import CONFIG from '../../core/configs/config';

class VideoPlayer extends React.Component {
    prevTimeRef = React.createRef();
    prevTimePausedRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            lastTimeViewed: props.lastTimeViewed,
            prevTime: props.lastTimeViewed,
            isSeek: false,
            isComplete: false,
            callRefresh: false,
            callRefreshBefore: false
        }
    }

    // Instantiate a Video.js player when the component mounts
    componentDidMount() {
        const { options, disableSeekBar, onTimeUpdate, timeUpdateStep, converting,
            disableForward, timeAutoPauseStep, lastTimeViewed,
            videoLength, onRefresh, onInitVideoPlayer, indexVideoPlayer, onRunningVideo } = this.props;
        
        const srcVideo = options?.sources?.[0]?.src;
        if(srcVideo === null) {
            // eslint-disable-next-line max-len
            // videojs.addLanguage('en', {'No compatible source was found for this media.': '「新規お申込み」にて登録完了後にログインしてご覧いただけます。'});
        }

        this.player = videojs(this.videoNode, { 
            controls: true,
            // controlBar: {
            //     pictureInPictureToggle: false
            // },
            ...options }, () => {
            // videojs.log('onPlayerReady', this);
        });

        const player = this.player;
        const self = this;

        if(onInitVideoPlayer) {
            onInitVideoPlayer(player, indexVideoPlayer);
        }

        if (lastTimeViewed) {
            player.currentTime(lastTimeViewed);
        }

        if (converting) {
            player.createModal('動画の処理中のでしばらくお待ちください', { uncloseable: true });
        }

        if (disableSeekBar) {
            player.controlBar.progressControl.disable();
        }

        if (disableForward) {
            videojs.use('*', function(player) {
                return {
                    // +++ Implement setSource() +++
                    setSource: function setSource(srcObj, next) {
                        next(null, srcObj);
                    },
                    // +++ Alter the setCurrentTime method +++
                    setCurrentTime: function setCurrentTime(ct) {
                        const { lastTimeViewed, prevTime } = self.state;
                        const currentTime = player.currentTime();
                        
                        if (ct < lastTimeViewed || ct <= prevTime || ct < currentTime ) {
                            self.setState({ isSeek: false });
                            return ct;
                        }

                        self.setState({ isSeek: true });
                        return currentTime;
                    }
                }
            });
        }

        if (onTimeUpdate) {
            player.on('timeupdate', function() {
                const currentTime = player.currentTime();
                const duration = player.duration();
                
                
                // const minutes = Math.floor((currentTime % 3600) / 60);
                // console.log('minutes: ', minutes);
                const seconds = Math.floor((currentTime % 3600) % 60);
                const { lastTimeViewed, callRefresh, callRefreshBefore } = self.state;
                self.setState(prev => {

                    if (prev.prevTime < currentTime) {
                        return { prevTime: currentTime }
                    }

                    return prev
                });

                // Call check is the last lecture
                if (currentTime >= duration - 10) {
                    if (!callRefreshBefore) {
                        self.setState({ callRefreshBefore: true });
                        onRefresh && onRefresh();
                    }
                }
                
                if (duration === currentTime || currentTime >= duration - 2) {
                    self.setState({ isComplete: true });

                    if (!callRefresh) {
                        self.setState({ callRefresh: true });
                        onRefresh && onRefresh();
                    }
                }

                const refresh = duration === currentTime || currentTime >= duration - 2;

                if (seconds === 1 && seconds !== self.prevTimeRef.current && (!lastTimeViewed || lastTimeViewed < currentTime)) {
                    self.prevTimeRef.current = seconds;
                    onTimeUpdate(currentTime, refresh);
    
                    self.handleUpdateLastTimeViewed(currentTime);
                }

                // check update time viewed
                if ((currentTime > timeUpdateStep && seconds !== self.prevTimeRef.current && seconds % timeUpdateStep === 0) 
                    || duration === currentTime) {

                    if (!lastTimeViewed || lastTimeViewed < currentTime || lastTimeViewed === currentTime || currentTime >= duration - 2) {
                        self.prevTimeRef.current = seconds;
                        const timeUpdate = (duration === currentTime || currentTime >= duration - 2)
                            ? (videoLength || currentTime) : currentTime;
                        onTimeUpdate(timeUpdate, refresh);
    
                        self.handleUpdateLastTimeViewed(timeUpdate);
                    }
                }

                // check auto pause
                if (Math.floor(currentTime) > lastTimeViewed && Math.floor(currentTime) > 0 && Math.floor(currentTime) % timeAutoPauseStep === 0) {
                    if (self.prevTimePausedRef.current !== Math.floor(currentTime)) {
                        self.setState({ isSeek: false });
                        
                        const timeUpdate = (duration === currentTime || currentTime >= duration - 2)
                            ? (videoLength || currentTime) : currentTime;
                        onTimeUpdate(timeUpdate, refresh);
    

                        self.handleUpdateLastTimeViewed(timeUpdate);

                        player.pause();
                    }
                    self.prevTimePausedRef.current = Math.floor(currentTime)
                }
            });

            
            player.on('pause', function() {
                // const { lastTimeViewed, isSeek } = self.state;
                // console.log('isSeek: ', isSeek);
                // if (isSeek) {
                //     return;
                // }
                const currentTime = player.currentTime();
                const duration = player.duration();
                const seconds = Math.floor((currentTime % 3600) % 60);
                const { callRefreshBefore } = self.state;
                // console.group('paused');
                // console.log('duration', duration);
                // console.log('currentTime', currentTime);
                // console.log('duration === currentTime', duration === currentTime);
                // console.log('currentTime >= duration - 2', currentTime >= duration - 2);
                // console.groupEnd();

                // Call check is the last lecture
                if (currentTime >= duration - 10) {
                    if (!callRefreshBefore) {
                        self.setState({ callRefreshBefore: true });
                        onRefresh && onRefresh();
                    }
                }

                if ((duration === currentTime || currentTime >= duration - 2)) {
                    self.prevTimeRef.current = seconds;
                    const timeUpdate = videoLength || currentTime;
                    onTimeUpdate(timeUpdate);

                    self.handleUpdateLastTimeViewed(timeUpdate);
                }
                // if (!lastTimeViewed || lastTimeViewed == 0 || (lastTimeViewed < currentTime) || lastTimeViewed == currentTime) {
                //     try {
                //         self.prevTimeRef.current = seconds;
                //         const timeUpdate = (lastTimeViewed === currentTime || lastTimeViewed > currentTime - 2)
                //             ? (videoLength || currentTime) : currentTime;
                //         onTimeUpdate(timeUpdate);

                //         self.handleUpdateLastTimeViewed(timeUpdate);
                //     } catch (error) {
                //         console.log('error: ', error);
                //     }
                    
                // }
            })
        }

        if (onRunningVideo) {
            player.on('play', function() {
                onRunningVideo();
            })
        }
    }

    handleUpdateLastTimeViewed = (currentTime) => {
        if (this.props.disableForward) {
            this.setState(prev => {
                if (prev.lastTimeViewed < currentTime) {
                    return { lastTimeViewed: currentTime, isSeek: false };
                }
                return { ...prev, isSeek: false };
            })
        }
    }

    // handleLoadedMetadata = () => {
    //     if (this.videoNode) {
    //         console.log('this.videoNode.duration: ', this.videoNode.duration);
    //         // this.props.onChangeDuration && this.props.onChangeDuration(this.videoNode.duration);
    //     }
    // }

    // Dispose the player when the component will unmount
    componentWillUnmount() {
        if (this.player) {
            // this.player.dispose();
        }
        this.prevTimeRef.current = undefined;
        this.prevTimePausedRef.current = undefined;
    }

    // Wrap the player in a `div` with a `data-vjs-player` attribute, so Video.js
    // won't create additional wrapper in the DOM.
    //
    // See: https://github.com/videojs/video.js/pull/3856
    render() {
        const { isComplete } = this.state;
        const { showCompleteText, linkGoTest, isFinalLecture } = this.props;
        return (
            <div className='video-preview' >
                <div data-vjs-player>
                    <video style={{ margin: 'auto' }} 
                        // onLoadedMetadata={this.handleLoadedMetadata}
                        ref={node => this.videoNode = node} width={this.props.width} height={this.props.height} className="video-js"></video>
                </div>
                {isComplete && !isFinalLecture && !linkGoTest &&  showCompleteText && 
                    <p className='mg-t-10 complete-video'>本コンテンツの視聴は完了しました。下の未視聴のコンテンツのなかから、次に視聴するものをお選びください。</p>}
                {linkGoTest && <p className='mg-t-10 complete-video'>全コンテンツの受講が終了しました。<a className='go-test' href={linkGoTest}>テストを実施してください</a> 。</p>}
            </div>
        );
    }
}

VideoPlayer.propTypes = {
    options: PropTypes.object, // https://videojs.com/guides/options
    disableSeekBar: PropTypes.bool,
    disableForward: PropTypes.bool,
    converting: PropTypes.bool,
    showCompleteText: PropTypes.bool,
    onTimeUpdate: PropTypes.func,
    lastTimeViewed: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
    timeUpdateStep: PropTypes.number, // seconds
    timeAutoPauseStep: PropTypes.number, // minutes
    videoLength: PropTypes.number, // minutes
    linkGoTest: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool
    ]),
    onRefresh: PropTypes.func,
    // totalLecturesActive: PropTypes.number
    isFinalLecture: PropTypes.bool,
    indexVideoPlayer: PropTypes.number,
    onInitVideoPlayer: PropTypes.func
}

VideoPlayer.defaultProps = {
    timeUpdateStep: CONFIG.timeUpdateStep,
    timeAutoPauseStep: CONFIG.timeAutoPauseStep, // seconds
    disableSeekBar: false,
    disableForward: true,
    options: {},
    width: 768,
    height: 432,
    converting: false,
    showCompleteText: false
}

export default VideoPlayer;