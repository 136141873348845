import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './styles.scss';
import classNames from 'classnames';
import moment from 'moment';
import { DATE_YMD } from '../../core/configs/constants';

const VerticalCarousel = (props) => {
    const {
        className,
        slides,
        useTooltipHover = false,
        allowTouchMove = true,
        onHandleNavigateDetailSlide
    } = props;
    
    return (
        <Swiper
            direction='vertical' // Chế độ trượt dọc
            slidesPerView={1}
            autoplay={{
                delay: 10000,
                disableOnInteraction: false, // Không dừng khi click vào slide
                pauseOnMouseEnter: true // Dừng autoplay khi hover
            }}
            loop={true}
            modules={[Autoplay]}
            allowTouchMove={allowTouchMove}
            className={classNames('carousel-container', className)}
        >
            {slides.map((slide) => (
                <SwiperSlide key={slide.id}>
                    <div className='slide-content'>
                        <div className='public-date flex-none'>{slide?.publicDate ? moment(slide.publicDate).format(DATE_YMD) : ''}</div>
                        {
                            useTooltipHover ? (
                                <button
                                    type='button'
                                    data-toggle='tooltip'
                                    data-placement='top'
                                    title={slide.title}
                                    onClick={slide?.url ? () => {
                                        const result = onHandleNavigateDetailSlide(slide.id);
                                        if (result) {
                                            window.open(slide.url,'_blank');
                                        }
                                    } : () => {}}
                                >
                                    <span className='title'>{slide.title}</span>
                                </button>
                            ) : (
                                <h3 className='title'>{slide.title}</h3>
                            )
                        }
                    </div>
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

export default VerticalCarousel;
