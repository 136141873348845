import { COURSE_PERIOD_STATUS, COURSE_STATUS_COMPLETE, USER_TYPE } from '../../../core/configs/constants';

// eslint-disable-next-line max-len
export const isShowJoinCourse = (token, course, userInfo) => 
    token && course?.statusUserCourse === null && userInfo?.roleId !== USER_TYPE.GROUP_MANAGER && course?.isAllowJoin && !course?.isOldCourse;
export const isShowCompletionTest = (token, course, coursePeriodStatus) => 
    token && coursePeriodStatus !== COURSE_PERIOD_STATUS.EXPIRED && 
    (course?.statusUserCourse === COURSE_STATUS_COMPLETE.USER_COURSE_NOT_DO_TEST && course?.completionDate === null);
export const isShowReTest = (token, course, coursePeriodStatus) => {
    return token && coursePeriodStatus !== COURSE_PERIOD_STATUS.EXPIRED &&
            (course?.statusUserCourse === COURSE_STATUS_COMPLETE.USER_COURSE_APPLY_CERTIFICATE || 
                (course?.statusUserCourse === COURSE_STATUS_COMPLETE.USER_COURSE_NOT_DO_TEST && course?.completionDate !== null));
};
export const isShowCourseStatus = (token, course) => token && (course?.statusUserCourse !== null || !course?.isOldCourse);
export const isViewOldCourse = (token, course, userInfo) => 
    token && userInfo?.roleId !== USER_TYPE.GROUP_MANAGER && course?.isOldCourse && course?.statusUserCourse === null;
export const isShowCancelButton = (token, course) => 
    token && course?.isCancelAttendance;