import ApiService from './ApiService';

class InformationApi extends ApiService {

    constructor() {
        super('information');
    }

    getAllInformationsForStudent(payload) {
        return this.get('', payload);
    }

    getDetailInformation({ id, payload }) {
        return this.get(id, payload);
    }

    viewInformation({ id, payload }) {
        return this.put(`${id}/view`, payload);
    }
}

export default InformationApi;