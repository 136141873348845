/* eslint-disable max-len */
import { Route, Routes } from 'react-router-dom';
import { GENERAL_TYPE, SYSTEM_PATH, USER_TYPE } from '../core/configs/constants';
import ContainerBody from '../components/Container/ContainerBody';
import NotFoundScreen from '../screens/404/NotFoundScreen';
import HomeScreen from '../screens/Home/HomeScreen';
import LoginScreen from '../screens/Login/LoginScreen';
import CoursesScreen from '../screens/Courses/CoursesScreen';
import CourseDtailScreen from '../screens/Courses/CourseDetailScreen';
import RegistrationFirstStepScreen from '../screens/Registrations/RegistrationFirstStepScreen';
import RegistrationSecondStepScreen from '../screens/Registrations/RegistrationSecondStepScreen';
import RegistrationThirdStepScreen from '../screens/Registrations/RegistrationThirdStepScreen';
import FaqScreen from '../screens/Faq/FaqScreen';
import { SendInquiryScreen } from '../screens/SendInquiry/SendInquiryScreen';
import LecturesScreen from '../screens/Lectures/LecturesScreen';
import LectureDetailScreen from '../screens/Lectures/LectureDetailScreen';
import MessagesScreen from '../screens/Messages/MessagesScreen';
import TBDScreen from '../screens/TBD/TBDScreen';
import { Authentication } from '../components';
import ForgotPasswordScreen from '../screens/ForgotPassword/ForgotPasswordScreen';
import MessagesDetailScreen from '../screens/Messages/MessagesDetailScreen';
import ProfileViewScreen from '../screens/Profile/ProfileViewScreen';
import ProfileEditScreen from '../screens/Profile/ProfileEditScreen';
import SetPasswordScreen from '../screens/SetPassword/SetPasswordScreen';
import CompletionTestScreen from '../screens/CompletionTest/CompletionTestScreen';
import TicketPlanListScreen from '../screens/TicketPlanList/TicketPlanListScreen';
import PaymentScreen from '../screens/Payment/PaymentScreen';
import AttendanceHistoryScreen from '../screens/Courses/AttendanceHistoryScreen';
import PurchaseHistoryScreen from '../screens/Profile/PurchaseHistoryScreen';
import PaymentStatus from '../screens/Payment/PaymentStatus/PaymentStatus';
import StudentGroupScreen from '../screens/GroupAdmin/StudentGroupScreen';
import StudentDetailScreen from '../screens/GroupAdmin/StudentDetailScreen';
import CourseListByStudentScreen from '../screens/GroupAdmin/CourseListByStudentScreen';
import StudentListByAttendanceCodeScreen from '../screens/GroupAdmin/StudentListByAttendanceCodeScreen';
import ChangePasswordScreen from '../screens/ChangePassword/ChangePasswordScreen';
import GeneralSettingScreen from '../screens/GeneralSetting/GeneralSettingScreen';
import NotifyDetailScreen from '../screens/Notify/NotifyDetailScreen';
import StudentListByAttendanceCodeScreenWithListUser from '../screens/GroupAdmin/StudentListByAttendanceCodeScreenWithListUser';
import ConfirmEmailScreen from '../screens/ConfirmEmail/ConfirmEmailScreen';
import TestResultScreen from '../screens/Courses/TestResultScreen';
import SurveyScreen from '../screens/CompletionTest/SurveyScreen';
import ToolCertificateScreen from '../screens/Profile/ToolCertificateScreen';
import InformationScreen from '../screens/Information/InformationScreen';
import InformationDetail from '../screens/Information/InformationDetail';

const Root = () => {

    const roleGA = [USER_TYPE.GROUP_MANAGER];

    const roleStudent = [USER_TYPE.STUDENT_INDIVIDUAL, USER_TYPE.STUDENT_GROUP];

    return(
        <Routes>
            <Route element={ <ContainerBody/> }>
                

                {/* not authentication and always access */}
                <Route element={<Authentication notAuth={true} alwaysAccess={true}/> }>

                    <Route path={SYSTEM_PATH.TBD_SCREEN} element={<TBDScreen/>} />

                    <Route path={SYSTEM_PATH.HOME} element={ <HomeScreen/> }/>

                    {/* theme */}
                    <Route path={SYSTEM_PATH.THEMES} element={<CoursesScreen/> }/>

                    {/* general information */}
                    <Route path={SYSTEM_PATH.ABOUT_US} 
                        element={ <GeneralSettingScreen pageTitle="運営内容" type={GENERAL_TYPE.ABOUT_US} /> }/>
                    <Route path={SYSTEM_PATH.USER_GUIDE} 
                        element={ <GeneralSettingScreen pageTitle="利用案内" type={GENERAL_TYPE.USER_GUIDE} /> }/>
                    <Route path={SYSTEM_PATH.TERM_OF_SERVICE}
                        element={ <GeneralSettingScreen pageTitle="利用規約" type={GENERAL_TYPE.TERM_SERVICE} /> }/>
                    <Route path={SYSTEM_PATH.PRIVACY_POLICY}
                        element={ <GeneralSettingScreen pageTitle="個人情報保護方針" type={GENERAL_TYPE.PRIVACY_POLICY} /> }/>
                    <Route path={SYSTEM_PATH.CANCELLATION_POLICY}
                        element={ <GeneralSettingScreen pageTitle="登録前の注意事項" type={GENERAL_TYPE.CANCELLATION_POLICY} /> }/>
                    <Route path={SYSTEM_PATH.SPECIFIED_COMMERCIAL_TRANSACTION_LAW_DISPLAY}
                        element={ <GeneralSettingScreen pageTitle="特定商取引法表示" type={GENERAL_TYPE.TRANSACTION_LAW} /> }/>
                    <Route path={SYSTEM_PATH.RECOMMENDED_ENVIRONMENT}
                        element={ <GeneralSettingScreen pageTitle="推奨環境" type={GENERAL_TYPE.RECOMMENDED_ENVIRONMENT} /> }/>
                    <Route path={SYSTEM_PATH.ABOUT_COMPANY}
                        element={ <GeneralSettingScreen pageTitle="elephamtとは" type={GENERAL_TYPE.ABOUT_COMPANY} /> }/>

                    {/* faqs */}
                    <Route path={SYSTEM_PATH.FAQ_SCREEN} element={ <FaqScreen/>} />

                    {/* contact us */}
                    <Route path={SYSTEM_PATH.SEND_INQUIRY} element={ <SendInquiryScreen/>} />

                </Route>

                {/* not authentication and only don't login and redirect home */}
                <Route element={<Authentication notAuth={true} alwaysAccess={false} redirectHome={true}/> }>

                    {/* login */}
                    <Route path={SYSTEM_PATH.LOGIN} element={ <LoginScreen/> }/>

                </Route>

                {/* not authentication and only don't login */}
                <Route element={<Authentication notAuth={true} alwaysAccess={false}/> }>

                    {/* password */}
                    <Route path={SYSTEM_PATH.FORGOT_PASSWORD} element={ <ForgotPasswordScreen /> }/>
                    <Route path={SYSTEM_PATH.SET_PASSWORD} element={ <SetPasswordScreen/> }/>

                    {/* confirm change email */}
                    <Route path={SYSTEM_PATH.CONFIRM_EMAIL} element={ <ConfirmEmailScreen/> }/>

                    {/* register */}
                    <Route path={SYSTEM_PATH.REGISTRATION_FIRST_STEP} element={<RegistrationFirstStepScreen/>} />
                    <Route path={SYSTEM_PATH.REGISTRATION_SECOND_STEP} element={<RegistrationSecondStepScreen/>} />
                    <Route path={SYSTEM_PATH.REGISTRATION_THIRD_STEP} element={<RegistrationThirdStepScreen/>} />

                </Route>

                {/* not authentication but GA not access */}
                <Route element={<Authentication role={roleStudent} notAuth={true} alwaysAccess={true}/> }>

                    {/* ticket */}
                    <Route path={SYSTEM_PATH.TICKETS} element={<TicketPlanListScreen/>} />

                </Route>
                

                {/* authentication general */}
                <Route element={<Authentication role={[...roleGA, ...roleStudent]} notAuth={false}/>}>

                    {/* profile */}
                    <Route path={SYSTEM_PATH.PROFILE} element={<ProfileViewScreen/>} />
                    <Route path={SYSTEM_PATH.PROFILE_EDIT} element={<ProfileEditScreen/>} />
                    <Route path={SYSTEM_PATH.CHANGE_PASSWORD} element={<ChangePasswordScreen/>} />

                    {/* message */}
                    <Route path={SYSTEM_PATH.MESSAGES} element={<MessagesScreen/>} />
                    <Route path={SYSTEM_PATH.MESSAGE_DETAIL} element={<MessagesDetailScreen/>} />
                    
                    {/* notify detail */}
                    <Route path={SYSTEM_PATH.NOTIFY_DETAIL} element={<NotifyDetailScreen/>} />

                    {/* course */}
                    <Route path={SYSTEM_PATH.COURSE_DETAIL} element={<CourseDtailScreen />} />

                    {/* information */}
                    <Route path={SYSTEM_PATH.INFORMATION_SCREEN} element={<InformationScreen />} />
                    <Route path={SYSTEM_PATH.INFORMATION_DETAIL} element={<InformationDetail />} />

                </Route>

                {/* authentication student */}
                <Route element={<Authentication role={roleStudent} notAuth={false}/>}>

                    {/* mypage */}
                    <Route path={SYSTEM_PATH.PURCHASE_HISTORY} element={<PurchaseHistoryScreen/>} />
                    <Route path={SYSTEM_PATH.ATTENDANCE_HISTORY} element={<AttendanceHistoryScreen />} />
                    <Route path={SYSTEM_PATH.TOOL_CERTIFICATE} element={<ToolCertificateScreen />} />

                    {/* payment */}
                    <Route path={SYSTEM_PATH.PAYMENT} element={<PaymentScreen/>} />
                    <Route path={SYSTEM_PATH.PAYMENT_STATUS} element={<PaymentStatus/>} />
                    <Route path={SYSTEM_PATH.PAYMENT_FREE_STATUS} element={<PaymentStatus/>} />

                    {/* lectures */}
                    <Route path={SYSTEM_PATH.LECTURES} element={<LecturesScreen/>} />
                    <Route path={SYSTEM_PATH.LECTURE_DETAIL} element={<LectureDetailScreen/>} />

                    {/* completion test */}
                    <Route path={SYSTEM_PATH.COMPLETION_TEST} element={<CompletionTestScreen/> }/>

                    {/* credit history */}
                    {/* <Route path={SYSTEM_PATH.CREDIT_HISTORY} element={<CreditHistoryScreen/> }/> */}

                    {/* test result history */}
                    <Route path={SYSTEM_PATH.TEST_RESULT_HISTORY} element={<TestResultScreen/> }/>

                    {/* survey */}
                    <Route path={SYSTEM_PATH.SURVEY_SCREEN} element={<SurveyScreen/> }/>

                </Route>

                {/* authentication group admin */}
                <Route element={<Authentication role={roleGA} notAuth={false}/>}>

                    {/* group admin */}
                    <Route path={SYSTEM_PATH.STUDENT_GROUP_LIST} element={<StudentGroupScreen/>} />
                    <Route path={SYSTEM_PATH.STUDENT_DETAIL} element={<StudentDetailScreen/>} />
                    <Route path={SYSTEM_PATH.COURSE_LIST_BY_STUDENT} element={<CourseListByStudentScreen/>} />
                    <Route path={SYSTEM_PATH.STUDENT_LIST_BY_ATTENDANCE_CODE} element={<StudentListByAttendanceCodeScreen/>} />
                    <Route path={SYSTEM_PATH.STUDENT_LIST_BY_ATTENDANCE_LIST_CODE} element={<StudentListByAttendanceCodeScreenWithListUser/>} />

                </Route>

                <Route path='*' element={ <NotFoundScreen/> }/>
            </Route>
        </Routes>
    )
}

export default Root;