import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import { Table } from '../../components';
import { useStore } from '../../core/utils/hook';
import {useLocation} from 'react-router-dom';

const StudentListByAttendanceCodeScreenWithListUser = observer(navigation  => {

    // params, query
    const { id } = useParams();
    const { state } = useLocation()

    // store
    const { groupAdminStore: { getDetailCompanyAttendanceRate, studentListWithAttCompany } } = useStore();

    // lifecycle
    useEffect(() => {
        const params =JSON.parse(state?.lstUser) 
        getDetailCompanyAttendanceRate(id, params);
    }, [])

    // columns table
    const columns = [
        {
            Header: '講座名',
            accessor: 'name',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {original?.firstName + ' ' + original?.lastName}
                    </div>
                );
            },
            width: '10%'
        },
        {
            Header: 'メールアドレス',
            accessor: 'email',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>{original?.email}</div>
                );
            },
            width: '25%'
        },
        {
            Header: '薬剤師名簿登録番号',
            accessor: 'pharmacistNumber',
            disableSortBy: true,

            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {original?.pharmacistNumber}
                    </div>
                );
            },
            width: '15%'
        },
        {
            Header: '受講期間',
            accessor: 'coursePeriodDate',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        { original?.coursePeriodDate?.replace(/-/g, '/')}
                    </div>
                );
            },
            width: '30%'
        }
    ]

    return(
        <div id='main' className='wrapper main-student-list main-view-student-detail main-student-list'>
            {
                // <div className='bres-cus'>
                //     <div className='container'>
                //         <ul>
                //             <li>
                //                 <Link to={SYSTEM_PATH.HOME}><img src='/images/icon-home.svg' alt='' /></Link>
                //             </li>
                //             {/* <li className='dash'>\</li>
                //             <li>ログイン</li> */}
                //         </ul>
                //     </div>
                // </div> 
            }
            <section className='content-student-list'>
                <div className='container'>
                    <h1 className='title-primary'>受講コード #{state?.attendanceCode} 別の受講者リスト</h1>
                    <Table
                        columns={columns}
                        data={studentListWithAttCompany?.elements || []}
                        disablePaging={true}
                        scrollHorizontalWidth={'768px'}
                        className='lst-attendance-table mg-t-35'
                    />
                </div>
            </section>
        </div>

    )
})

export default StudentListByAttendanceCodeScreenWithListUser;