import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { GENDER_MAP, WORK_FORM_MAP } from '../../core/configs/constants';
import { useStore } from '../../core/utils/hook';
import CourseListByStudentScreen from './CourseListByStudentScreen';

const StudentDetailScreen = observer(props => {

    // params
    const { id } = useParams();

    // store
    const { groupAdminStore: { studentDetail, getStudentDetail } } = useStore();

    // lifecycle
    useEffect(() => {
        getStudentDetail(id);
    }, [])

    return(
        <div id='main' className='wrapper main-student-list main-view-student-detail'>
            {/* <div className='bres-cus'>
                <div className='container'>
                    <ul>
                        <li>
                            <Link to={SYSTEM_PATH.HOME}><img src='/images/icon-home.svg' alt='logo-home'/></Link>
                        </li>
                        <li className='dash'>\</li>
                        <li>受講者リスト</li>
                        <li className='dash'>\</li>
                        <li>受講者詳細</li>
                    </ul>
                </div>
            </div> */}
            <section className='content-student-list'>
                <div className='container'>
                    <h1 className='title-primary'>受講者リスト</h1>
                    <div className='title-title'>
                        <h2 className='title-2'>受講講座履歴</h2>
                    </div>
                    <CourseListByStudentScreen isComponent={true}/>
                </div>
            </section>
            <section className='top-student-list'>
                <div className='container'>
                    <div className='title-title'>
                        <h2 className='title-2'>登録情報</h2>
                    </div>
                    <div className='content-student-detail'>
                        <div className='item'>
                            <div className='left'>
                                <span>名前</span>
                            </div>
                            <div className='right'>
                                <span>{studentDetail?.firstName} {studentDetail?.lastName}</span>
                            </div>
                        </div>
                        <div className='item'>
                            <div className='left'>
                                <span>名前(フリガナ)</span>
                            </div>
                            <div className='right'>
                                <span>{studentDetail?.firstNameFuri} {studentDetail?.lastNameFuri}</span>
                            </div>
                        </div>
                        <div className='item'>
                            <div className='left'>
                                <span>性別</span>
                            </div>
                            <div className='right'>
                                <span>{GENDER_MAP[studentDetail?.gender]}</span>
                            </div>
                        </div>
                        <div className='item'>
                            <div className='left'>
                                <span>メールアドレス</span>
                            </div>
                            <div className='right'>
                                <span>{studentDetail?.email}</span>
                            </div>
                        </div>
                        <div className='item'>
                            <div className='left'>
                                <span>薬剤師名簿登録番号</span>
                            </div>
                            <div className='right'>
                                <span>{studentDetail?.pharmacistNumber}</span>
                            </div>
                        </div>
                        <div className='item'>
                            <div className='left'>
                                <span>沖/外選択</span>
                            </div>
                            <div className='right'>
                                <span>{WORK_FORM_MAP[studentDetail?.workForm]}</span>
                            </div>
                        </div>
                        <div className='item'>
                            <div className='left'>
                                <span>団体名</span>
                            </div>
                            <div className='right'>
                                <span>{studentDetail?.groupName}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    )
})

export default StudentDetailScreen;