import { action, flow, makeObservable, observable } from 'mobx';
import { AuthApi } from '../apis';
import { SYSTEM_PATH } from '../core/configs/constants';
import { getSavedInfo, getSavedInfoJson } from '../core/utils/browser';


class AuthStore {

    userInfo = getSavedInfoJson('user');
    token = getSavedInfo('token');
    userRegisterInfo = null;
    registerCurrentStep=1; 

    showLoadingSrc = true;

    showAlertToolCertificate = getSavedInfo('showAlertToolCertificate');

    constructor(rootStore) {
        makeObservable(this, {
            userInfo: observable,
            token: observable,
            registerCurrentStep:observable,
            userRegisterInfo: observable,
            showLoadingSrc: observable,
            showAlertToolCertificate: observable,
            clearAuthentication: action.bound,
            login: flow.bound,
            registerEmail:flow.bound,
            registerUser: flow.bound,
            confirmEmail: flow.bound,
            clearAuthenticationWithSetPassword: action.bound,
            changeStepRegister: action.bound,
            getUserRegisterInfomaiton: flow.bound,
            setShowLoadingSrc: action.bound,
            setShowAlertToolCertificate: action.bound
        })
        this.rootStore = rootStore;
        this.api = new AuthApi();
    }

    saveUserInfo(data, remember) {
        if (!data) {
            return;
        }

        const storage = remember ? localStorage : sessionStorage;

        if (data.token) {
            this.token = data.token;
            storage.setItem('token', data.token);
        }

        if (data.user) {
            this.userInfo = data.user;
            const { firstName, lastName, firstNameFuri, lastNameFuri, ...payload } = data.user;
            storage.setItem('user', JSON.stringify(payload));
        }
    }

    *login(payload, remember) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.login, payload);
            if(res?.ok) {
                this.saveUserInfo(res?.data, remember);
                localStorage.setItem('showAlertToolCertificate', true);
                return true;
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }

    *registerEmail(payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.registerEmail, payload);
            return res;
        } catch (error) {
            console.log(error);
        }
    }

    *registerUser(payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.registerUser, payload);
            return res;
        } catch (error) {
            console.log(error);
        }
    }

    *getUserRegisterInfomaiton(userId) {
        this.clearAuthenticationWithSetPassword();
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getUserConfirmation, userId);
            if(res?.ok) {
                this.userRegisterInfo = res?.data;
                return true;
            }
            return res;
        } catch (error) {
            console.log(error);
        }
    }

    *confirmEmail(payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.confirmEmail, payload, false, null, false, true);
            if(res?.data) {
                return res?.data;
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }

    changeStepRegister(step) {
        this.registerCurrentStep = step
    }

    clearAuthenticationWithSetPassword(){
        localStorage.clear();
        sessionStorage.clear();
        this.token = null;
    }

    clearAuthentication() {
        localStorage.clear();
        sessionStorage.clear();
        this.token = null;
        window.location.href = SYSTEM_PATH.HOME;
    }

    setShowLoadingSrc(value) {
        this.showLoadingSrc  =value;
    }

    setShowAlertToolCertificate(value) {
        this.showAlertToolCertificate = value;
    }
}

export default AuthStore;