import { action, makeObservable, observable } from 'mobx';
import { ReactNotifications } from '../components';
import { MSG } from '../core/configs/constants';
import { handleSaveFile } from '../core/utils/file';

class ApiStore {

    requestCount = 0;
    loading = false;

    constructor(rootStore) {
        makeObservable(this, {
            loading: observable,
            showLoading: action.bound,
            hideLoading: action.bound,
            setLoading: action.bound
        })
        this.rootStore = rootStore;
    }

    showLoading() {
        this.loading = true;
    }

    hideLoading() {
        this.loading = false;
    }

    setLoading(loading) {
        this.loading = loading;
    }

    handlerGeneralResponseSuccess = (response) => {
        if(response) {
            const type = response.headers['content-type'];
            const isExport = ['application/pdf','application/zip', 'text/csv; charset=utf-8'].includes(type);
            if (response?.status === 200) return isExport ? handleSaveFile(response) : response?.data;
            // ReactNotifications('error', MSG['api.response.worng_format'], '');
            this.rootStore.modalStore.openErrorModal('エラー', MSG['api.response.worng_format']);
        }
        
        throw response;
    }

    handlerGeneralResponseError = async (response, handleErrorByChild, fb = null, isNavigateHome=true, disableAlertError = false) => {
        if (handleErrorByChild) {
            this.rootStore.modalStore.openAlert(response?.data?.message ?? '', () => window.location.reload(), 'small', '再読み込み');
            return;
        }

        try {
            if(response?.data instanceof Blob && response?.data?.type === 'application/json' ) {
                const responseData = await response?.data?.text() || {};
                response.data = (typeof responseData === 'string') ? JSON.parse(responseData) : responseData;
            }
            if(response?.status === 401 || response?.status === 403) {
                if (response?.data?.message) {
                    // ReactNotifications('error', response?.data?.message, '');
                    // this.rootStore.modalStore.openErrorModal('エラー', response?.data?.message);
                    this.rootStore.modalStore.openAlert(response?.data?.message, () => {
                        this.rootStore.authStore.clearAuthentication();
                    })
                   
                } else {
                    // ReactNotifications('error', MSG['api.response.authorization'], '');
                    // this.rootStore.modalStore.openErrorModal('エラー', MSG['api.response.server_error']);
                    this.rootStore.modalStore.openAlert(response?.data?.message, () => {
                        this.rootStore.authStore.clearAuthentication();
                    })
                   
                }
                return;
            }
            else if (response?.status >= 500) {
                if (response?.data?.message) {
                    // ReactNotifications('error', response?.data?.message, '');
                    this.rootStore.modalStore.openErrorModal('エラー', response?.data?.message);
                } else {
                    // ReactNotifications('error', MSG['api.response.server_error'], '');
                    this.rootStore.modalStore.openErrorModal('エラー', MSG['api.response.server_error']);
                }
                return;
            } else if (response?.status) {
                if(!disableAlertError) {
                    try {
                        const type = response.headers['content-type'];
                        const blob = new Blob([response.data], { type: type });
                        const parseBlob = JSON.parse(await blob.text());
                        if (parseBlob?.message) {
                            this.rootStore.modalStore.openErrorModal('エラー', parseBlob?.message);
                        } else {
                            this.rootStore.modalStore.openErrorModal('エラー', response?.data?.message || MSG['api.response.no_message'])
                        }
                        
                    } catch (error) {
                        this.rootStore.modalStore.openErrorModal('エラー', response?.data?.message || MSG['api.response.no_message']);
                    }
                }
                
                fb && fb();

                return response?.data;
            }
        } catch {
            // ReactNotifications('error', MSG['api.response.no_network'], '');
            this.rootStore.modalStore.openErrorModal('エラー', MSG['api.response.no_network']);
        }
    }

    call(context, request, payload = null, handleErrorByChild = false, fb = null, disableLoading = false, disableAlertError = false) {
        
        let isNavigateHome = !context?.url?.indexOf('student/message');
       
        this.requestCount += 1;
        if(!disableLoading) this.showLoading();
        return request.apply(context, Object.prototype.toString.call(payload) === '[object Array]' ? payload : [payload])
            .then(this.handlerGeneralResponseSuccess)
            .catch(response => this.handlerGeneralResponseError(response?.response, handleErrorByChild, fb, isNavigateHome, disableAlertError))
            .finally(() => {
                this.requestCount -= 1;
                if(this.requestCount === 0) {
                    this.hideLoading();
                }
            })
    }
}

export default ApiStore;
