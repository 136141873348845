import { observer } from 'mobx-react';
import moment from 'moment';
import { useMemo } from 'react';
import { COURSE_STATUS_COMPLETE, CREDIT_APPLICATION_STATUS, DATE_YMD, MSG, SYSTEM_PATH } from '../../../core/configs/constants';
import { Link, useNavigate } from 'react-router-dom';
import { useStore } from '../../../core/utils/hook';
import { flowResult } from 'mobx';
import { ReactNotifications } from '../../../components';

function AttendanceHistoryItem({ item, onToggleCheck, onSelectRow, isHaveCourseCanCancelAttendance, fetchData }) {
    // other
    const navigate = useNavigate();

    //store
    const {
        modalStore: { openWarningModal },
        userStore: { cancelAttendance, getUserInfoHeader }
    } = useStore();

    const courseType = useMemo(() => {
        /**
            [0] -> 研修認定薬剤師 || 研修
            [0,1] -> 精神薬学会認定薬剤師 || 精神
            [0,2] -> 漢方薬・生薬認定薬剤師 || 研修 / 小児
            [0,3] -> 小児薬物療法認定薬剤師 || 研修 / 漢方
         */
        try {
            if (!item?.typeCourse?.length) return '';
            const types = [...item.typeCourse].map(e => Number(e));

            if (types.includes(0) && types.length === 1) {
                return {
                    status: 0,
                    content: "<span class='title-box xanh'>研修</span>"
                };
            }

            if (types.length === 2 && types.includes(0) && types.includes(1)) {
                return {
                    status: 1,
                    content: "<span class='title-box xanh'>研修</span><span class='title-box tim'>精神</span>"
                };
            }

            if (types.length === 2 && types.includes(0) && types.includes(2)) {
                return {
                    status: 2,
                    content: "<span class='title-box xanh'>研修</span><span class='title-box cam'>漢方</span>"
                };
            }

            if (types.length === 2 && types.includes(0) && types.includes(3)) {
                return {
                    status: 3,
                    content: "<span class='title-box xanh'>研修</span><span class='title-box vang'>小児</span>"
                };
            }

            if(item?.isNonCertificate) {
                return {
                    content: '-'
                }
            }

            return '';
        } catch (error) {
            return ''
        }
    }, [item?.typeCourse])

    const handleCancelAttendance = async () => {
        openWarningModal(MSG['cancel.attendance.update.confirm'], async () => {
            const res = await flowResult(cancelAttendance(item.courseId));
            if (res) {
                fetchData && fetchData();
                getUserInfoHeader();
                ReactNotifications('success', MSG['inform.success.update']);
            }
        })
    }

    return (
        <tr>
            <td>
                <input onChange={(event) => onToggleCheck(item.rowId, event.target.checked)} checked={item.isChecked} type="checkbox" />
            </td>
            <td width={'200px'}>
                <a className='text-link' href={SYSTEM_PATH.COURSE + item?.courseId}>{item.courseName}</a>
            </td>
            <td>
                {
                    item.status === COURSE_STATUS_COMPLETE.USER_COURSE_APPLY_CERTIFICATE ? 
                        '受講完了' : 
                        '受講中'
                }
            </td>
            <td>{item.endDate ? moment(item.endDate).format(DATE_YMD) : ''}</td>
            <td>
                {
                    item.testResultHistory !== null && item.testResultHistory !== undefined ? 
                        <Link 
                            className='text-link' 
                            to={`/test-result-history/${item?.courseId}`} 
                            target='_blank'>{item.achievement}%</Link>
                        : <div className=''>{item.achievement ? `${item.achievement}%` : ''}</div>
                }
            </td>
            { item.isNonCertificate ? 
                <td>-</td> :
                <td>
                    { 
                        ( item.status === COURSE_STATUS_COMPLETE.USER_COURSE_APPLY_CERTIFICATE ) ? 
                            ( item.isAppliedCert ? CREDIT_APPLICATION_STATUS.COMPLETED.label : CREDIT_APPLICATION_STATUS.NO_APPLY.label ) : 
                            ''
                    }
                </td>
            }
            <td>
                <div className='list-note'>
                    <p dangerouslySetInnerHTML={{ __html: courseType.content }} 
                        className='rows-center mg-b-0 d-flex flex-gap-10 align-items-center justify-content-center flex-wrap'>
                    </p>
                </div>
            </td>
            <td>
                {courseType.status === 1 && <a className='btn btn-danger width-120 mg-l-120' onClick={() => onSelectRow(item.courseId)}>
                    <i className="fa fa-file-pdf-o" aria-hidden="true"></i>修了証 PDF
                </a>}
            </td>
            {
                isHaveCourseCanCancelAttendance ? (
                    item.isCancelAttendance ? (
                        <td>
                            <div
                                onClick={handleCancelAttendance}
                                className='d-flex align-items-center flex-gap-5 cancel-attendance-btn-for-history'>
                                <img className='flex-none' src='/images/icon-cancel-attendance.svg' alt='' /> 
                                <span className=''>受講をキャンセル</span>
                            </div>
                        </td>
                    ) : <td></td>
                ) : <></>
            }
        </tr>
    );
}

export default observer(AttendanceHistoryItem);
