import { observer } from 'mobx-react';
import { useEffect, useMemo, useState } from 'react';
import { useStore } from '../../core/utils/hook';
import { Table, TreeViewCustom } from '../../components';
import { Link, useNavigate } from 'react-router-dom';
import NotifyList from './components/NotifyList';
import classNames from 'classnames';
import { flowResult, toJS } from 'mobx';
import { SYSTEM_PATH } from '../../core/configs/constants';
import VerticalCarousel from '../../components/CarouselSwiper/VerticalCarousel';

const GAHomeScreen = observer((props) => {
    // other
    const navigate = useNavigate();

    // state
    const [ expandedIds, setExpandedIds ] = useState([]);
    const [ highlightExpand, setHighlightExpand ] = useState([]);
    const [ groupSelectedIds, setGroupSelectedIds ] = useState([]);
    const [ isOpenFilterModal, setIsOpenFilterModal ] = useState(false);
    const [ groupTreeFilterIds, setGroupTreeFilterIds ] = useState([]);
    const [ groupFilterIds, setGroupFilterIds ] = useState([]);

    // store
    const { 
        groupAdminStore: { 
            attendancesOfCompany, companyAttendanceRateList, getAllChildrenGroupById, childrenOfGroup,
            getAttendancesOfCompany, getCompanyAttendanceRate, clean
        },
        userStore: {
            userInfoHeader
        },
        informationStore: { lstInformation, getAllInformationsForStudent, viewInformation }
    } = useStore();

    // memo
    const formatDataCarouselInformation = useMemo(() => {
        if(!lstInformation || lstInformation?.length < 0) return [];
        return lstInformation.map(item => ({
            id: item.id,
            title: item.title,
            url: `${SYSTEM_PATH.INFORMATION_SCREEN}/${item.id}`,
            publicDate: item.publicDate
        }))
    }, [lstInformation])

    // lifecycle
    useEffect(() => {
        clean();
        if(userInfoHeader?.id) {
            onFetchData();
            getAllChildrenGroupById(userInfoHeader?.groupId);
            getAllInformationsForStudent({ isHomepage: true });
        }
    }, [userInfoHeader])

    useEffect(() => {
        if (childrenOfGroup?.length > 0) {
            setExpandedIds(Array.from({ length: childrenOfGroup.length + 1 }, (_, index) => index + 1))
            setHighlightExpand(Array.from({ length: childrenOfGroup.length + 1 }, (_, index) => index + 1));
            setGroupTreeFilterIds(Array.from({ length: childrenOfGroup.length + 1 }, (_, index) => index + 1));
            setGroupSelectedIds(childrenOfGroup.map(item => item.id));
            setGroupFilterIds(childrenOfGroup.map(item => item.id));
        }
    }, [childrenOfGroup])

    // function
    const onFetchData = () => {
        onGetAttendancesOfCompany();
        onGetCompanyAttendanceRate();
        setGroupTreeFilterIds(highlightExpand);
        setGroupFilterIds(groupSelectedIds);
    }

    const onGetAttendancesOfCompany = (data = {}) => {
        getAttendancesOfCompany({
            ...data,
            groupIds: groupSelectedIds,
            parentGroup: userInfoHeader?.groups?.length > 1
        });
    }

    const onGetCompanyAttendanceRate = (data = {}) => {
        getCompanyAttendanceRate({
            ...data,
            groupIds: groupSelectedIds,
            parentGroup: userInfoHeader?.groups?.length > 1
        });
    }

    const onHandLeExpand = (e) => {
        if (!expandedIds.includes(e.id)) {
            setExpandedIds([...expandedIds, e.id].sort((a, b) => a - b));
        } else {
            setExpandedIds([...expandedIds].filter(item => item !== e.id))
        }
    }

    const onCheckedBox = (event, element) => {
        if (element.name === '全ての団体') {
            if (event.target.checked) {
                setHighlightExpand(Array.from({ length: childrenOfGroup.length + 1 }, (_, index) => index + 1));
                setGroupSelectedIds(childrenOfGroup.map(item => item.id));
            } else {
                setHighlightExpand([]);
                setGroupSelectedIds([]);
            }
        } else {
            if (event.target.checked) {
                setHighlightExpand((prev) => {
                    const temp = [...prev, element.id];
                    if (temp.length === childrenOfGroup.length) {
                        setGroupSelectedIds(childrenOfGroup.map(item => item.id));
                        return Array.from({ length: childrenOfGroup.length + 1 }, (_, index) => index + 1);
                    } else {
                        setGroupSelectedIds((prev) => [...prev, element.metadata.id])
                        return temp;
                    }
                });
            } else {
                setHighlightExpand(prev => {
                    const temp = prev.filter(item => item !== element.id);
                    if (temp.length === childrenOfGroup.length) {
                        setGroupSelectedIds((prev) => prev.filter(item => item !== element.metadata.id))
                        return temp.filter(item => item !== 1)
                    } else {
                        setGroupSelectedIds((prev) => prev.filter(item => item !== element.metadata.id));
                        return temp;
                    }
                });
            }
        }
    }

    function buildTree(data) {
        const map = {};
        const temp = data.map(item => {
            if (item.level === 1) {
                item.parent_id = null
            }
            return item;
        })
        temp.forEach(item => {
            map[item.id] = {
                name: item.name,
                children: [],
                metadata: { id: item.id, parent: item.parent_id }
            };
        });
        
        temp.forEach(item => {
            if (item.parent_id !== null && map[item.id]) {
                if (map[item.parent_id]) {
                    map[item.parent_id].children.push(map[item.id]);
                }
            }
        });
        
        return Object.values(map).filter(node => node.metadata.parent === null);
    }

    const handleShowFilterChildrenGroupModal = () => {
        setIsOpenFilterModal(true);
    }

    const onCloseFilterModal = () => {
        setIsOpenFilterModal(false);
        if(!(groupTreeFilterIds.every(item => highlightExpand.includes(item)) && groupTreeFilterIds.length === highlightExpand.length)) {
            setHighlightExpand(groupTreeFilterIds);
            setGroupSelectedIds(groupFilterIds);
        }
    }

    const onHandleNavigateDetailSlide = async (id) => {
        const res = await flowResult(viewInformation(id, {}));
        if (res) return res;
        return null;
    }

    // table columns
    const attendanceColumns = useMemo(() => {
        const isParentGroup = userInfoHeader?.groups?.length > 1;

        const defaultColumns = [
            {
                Header: '受講コード',
                accessor: 'attendanceCode',
                disableSortBy: true,
                Cell: ({ row: { original } }) => {
                    return (
                        <div>
                            {original?.attendanceCode}
                        </div>
                    );
                },
                width: isParentGroup ? '15%' : '25%'
            },
            {
                Header: '受講コース',
                accessor: 'ticketName',
                disableSortBy: true,
                Cell: ({ row: { original } }) => {
                    return (
                        <div>
                            {original?.ticketName}
                        </div>
                    );
                },
                width: '25%'
            },
            {
                Header: '制限人数',
                accessor: 'limit',
                disableSortBy: true,
                Cell: ({ row: { original } }) => {
                    return (
                        <div>
                            {original?.limit}
                        </div>
                    );
                },
                width: isParentGroup ? '10%' : '15%'
            },
            {
                Header: '有効期限',
                accessor: 'expirationDate',
                disableSortBy: true,
                Cell: ({ row: { original } }) => {
                    return (
                        <div>
                            { original?.expirationDate?.replace(/-/g, '/')}
                        </div>
                    );
                },
                width: isParentGroup ? '15%' : '20%'
            },
            {
                Header: '受講者数',
                accessor: 'numberUsed',
                disableSortBy: true,
                Cell: ({ row: { original } }) => {
                    return (
                        <div>
                            <Link to={`/student-list-by-attendance-code/${original.id}`}>{ original?.numberUsed }</Link>
                        </div>
                    );
                },
                width: isParentGroup ? '10%' : '15%'
            }
        ]
        
        if(isParentGroup) {
            defaultColumns.splice(1, 0, {
                Header: '団体名',
                accessor: 'groupName',
                disableSortBy: true,
                Cell: ({ row: { original } }) => {
                    return (
                        <div>
                            {original?.group?.name}
                        </div>
                    );
                },
                width: '20%'
            })
        }

        return defaultColumns;
    }, [userInfoHeader?.groups?.length])

    const companyAttendanceColumns = useMemo(() => {
        const isParentGroup = userInfoHeader?.groups?.length > 1;

        const defaultColumns = [
            {
                Header: '受講コード',
                accessor: 'attendanceCode',
                disableSortBy: true,
                Cell: ({ row: { original } }) => {
                    return (
                        <div>{original?.attendanceCode}</div>
                    );
                },
                width: isParentGroup ? '15%' : '20%'
            },
            {
                Header: '受講コース',
                accessor: 'ticketName',
                disableSortBy: true,
                Cell: ({ row: { original } }) => {
                    return (
                        <div>
                            {original?.ticketName}
                        </div>
                    );
                },
                width: '17.5%'
            },
            {
                Header: '消化率50%未満',
                accessor: 'lt50',
                disableSortBy: true,
                Cell: ({ row: { original } }) => {
                    return (
                        <div>
                            <Link 
                                to={`/student-list-by-attendance-list-code/${original.groupAttendanceId}`} 
                                state={{attendanceCode: original?.attendanceCode, lstUser : JSON.stringify(original.userIDlt50) }}>
                                { original?.lt50}
                            </Link>
                        </div>
                    );
                },
                width: isParentGroup ? '15%' : '18.5%'
            },
            {
                Header: '消化率50%以上100%未満',
                accessor: 'gte50',
                disableSortBy: true,
                Cell: ({ row: { original } }) => {
                    return (
                        <div>
                            <Link 
                                to={`/student-list-by-attendance-list-code/${original.groupAttendanceId}`} 
                                state={{attendanceCode: original?.attendanceCode, lstUser : JSON.stringify(original.userIDgte50)}}>
                                { original?.gte50}
                            </Link>
                        </div>
                    );
                },
                width: isParentGroup ? '15%' : '18.5%'
            },
            {
                Header: '消化率100%',
                accessor: 'eq100',
                disableSortBy: true,
                Cell: ({ row: { original } }) => {
                    return (
                        <div>
                            <Link 
                                to={`/student-list-by-attendance-list-code/${original.groupAttendanceId}`} 
                                state={{attendanceCode: original?.attendanceCode, lstUser : JSON.stringify(original.userIDeq100)}}>
                                { original?.eq100 }
                            </Link>
                        </div>
                    );
                },
                width: isParentGroup ? '15%' : '18.5%'
            }
        ]

        if(isParentGroup) {
            defaultColumns.splice(1, 0, {
                Header: '団体名',
                accessor: 'groupName',
                disableSortBy: true,
                Cell: ({ row: { original } }) => {
                    return (
                        <div>
                            {original?.group?.name}
                        </div>
                    );
                },
                width: '20%'
            })
        }

        return defaultColumns;
    }, [userInfoHeader?.groups?.length])

    return(
        <>
            <div id='main' className='wrapper main-student-list main-view-student-detail home-page-group-admin'>
                <section className='Banner-Home'>
                    {
                        lstInformation?.length > 0 && (
                            <div className='carousel-information d-flex align-items-center bg-gray'>
                                <div className='carousel-information-title'>
                                    <span className=''>Pharma Info</span>
                                    <div
                                        className='pd-lr-20 view-lst-information-hyperlink-title'
                                        onClick={() => navigate(SYSTEM_PATH.INFORMATION_SCREEN)}
                                    ><span className='hyperlink'>一覧をみる</span>{'>'}{'>'}</div>
                                </div>
                                <VerticalCarousel
                                    className='height-40'
                                    slides={formatDataCarouselInformation}
                                    useTooltipHover
                                    allowTouchMove={false}
                                    onHandleNavigateDetailSlide={onHandleNavigateDetailSlide}
                                />
                                <div
                                    className='pd-lr-20 view-lst-information-hyperlink'
                                    onClick={() => navigate(SYSTEM_PATH.INFORMATION_SCREEN)}
                                ><span className='hyperlink'>一覧をみる</span>{'>'}{'>'}</div>
                            </div>
                        )
                    }
                    <NotifyList/>
                </section>

                <section className='home-statistical pd-t-75'>
                    <div className='container d-flex justify-content-between align-items-center flex-wrap'>
                        <h2 className='title-primary mg-b-0'>【ダッシュボード】</h2>
                        {
                            childrenOfGroup?.length > 1 && (
                                <button
                                    className='filter-children-group mg-t-15'
                                    onClick={handleShowFilterChildrenGroupModal}>
                                    {`団体で絞り込む${groupTreeFilterIds?.length === childrenOfGroup?.length + 1 ? 
                                        ' : 全ての団体' : ` : ${groupTreeFilterIds?.length}団体`}`}
                                </button>
                            )
                        }
                    </div>

                    <section className='content-student-list content-view-student-detail pd-t-20'>
                        <div className='container'>
                            <h1 className='title-primary'>受講コード別の受講者数</h1>
                            <Table
                                columns={attendanceColumns}
                                data={attendancesOfCompany.elements || []}
                                enableServerSidePaging={true}
                                initialTableState={attendancesOfCompany.paginate}
                                onFetch={onGetAttendancesOfCompany}
                                disablePaging={false}
                                disableSelectionPageSize={true}
                                showPagingInfo={false}
                                className='lst-attendance-table mg-t-35'
                            />
                        </div>
                    </section>

                    <section className='content-student-list content-view-student-detail content-view-student-detail-2'>
                        <div className='container'>
                            <h1 className='title-primary'>受講コード別コース消化率</h1>
                            <Table
                                columns={companyAttendanceColumns}
                                data={companyAttendanceRateList.elements || []}
                                enableServerSidePaging={true}
                                initialTableState={companyAttendanceRateList.paginate}
                                onFetch={onGetCompanyAttendanceRate}
                                disablePaging={false}
                                disableSelectionPageSize={true}
                                showPagingInfo={false}
                                className='lst-attendance-table mg-t-35'
                            />
                        </div>
                    </section>
                </section>            
            </div>
            {
                isOpenFilterModal && (
                    <div className='filter-modal-overlay'>
                        <div className='filter-modal-content width-600'>
                            <div className='modal-header pd-lr-15 pd-tb-10'>
                                <h4 className='modal-title'>団体で絞り込む</h4>
                                <button
                                    type='button'
                                    className='btn-close'
                                    onClick={() => onCloseFilterModal()}
                                >
                                </button>
                            </div>
                            <TreeViewCustom
                                data={{
                                    name: 'すべてのテーマ',
                                    children: [
                                        {
                                            children: [...buildTree(childrenOfGroup)],
                                            name: '全ての団体',
                                            metadata: {

                                            }
                                        }
                                    ]
                                }}
                                className='min-height-200 max-height-500 overflow-auto align-items-start'
                                // onClickTreeName={onClickTreeName}
                                onHandLeExpand={onHandLeExpand}
                                expandedIds={expandedIds}
                                onCheckedBox={onCheckedBox}
                                checkedIds={highlightExpand}
                                isCheckboxSelected
                            />
                            <div
                                className='add-attendance-code
                                parent-company-filter-modal d-flex pd-20 align-items-center justify-content-center flex-gap-50'>
                                <button
                                    style={{ height: '42px', width: '150px' }}
                                    type='button' className='cancel'
                                    onClick={onCloseFilterModal}>キャンセル</button>
                                <button 
                                    disabled={highlightExpand?.length === 0}
                                    style={{ height: '42px', width: '150px' }}
                                    type='button' 
                                    className={classNames('submit', highlightExpand?.length === 0 && 'opacity-50')}
                                    onClick={() => {
                                        setIsOpenFilterModal(false);
                                        onFetchData();
                                    }}
                                >適用</button>
                            </div>
                        </div>
                    </div>
                )
            }
        </>

    )
})

export default GAHomeScreen;