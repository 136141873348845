/* eslint-disable max-len */
import { flowResult, toJS } from 'mobx';
import { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ReactNotifications, VideoPlayer } from '../../../components';
import { COURSE_STATUS_COMPLETE, COURSE_TYPE, COURSE_TYPE_STRING, DATE_TIME_YMD, DATE_YMD, LEVEL_COURSE_STRING, MSG, SYSTEM_PATH, THEME_TYPE } from '../../../core/configs/constants';
import { convertVideoDuration, extractContent, formatTimeFromVideoLength, numberWithCommas } from '../../../core/utils/common';
import { useStore } from '../../../core/utils/hook';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { Pie } from 'react-chartjs-2';
import moment from 'moment';
import classNames from 'classnames';
import ReactHtmlParser from 'react-html-parser';
import { isShowCancelButton, isShowCompletionTest, isShowCourseStatus, isShowJoinCourse, isShowReTest, isViewOldCourse } from '../ultis/courses.ultis';

import './styles.scss';

const data = {
    labels: ['受講中または受講完了', ' 未受講'],
    datasets: [
        {
            label: '受講割合',
            data: [0, 0],
            backgroundColor: [
                '#fbb03b',
                '#0077b6'
            ]
        }
    ]
};

const CourseCard = ({ course, onFetchData, handleSearch }) => {
    const [dataPieChart, setDataPieChart] = useState(data)
    const [showMore, setShowMore] = useState(false);
    const [isShowTitleVideoSample, setIsShowTitleVideoSample] = useState(true);
    const [isShowCourseDetail, setIsShowCourseDetail] = useState(false);
    const [currentTabCourseDetail, setCurrentTabCourseDetail] = useState(1);

    const navigate = useNavigate();

    const {
        courseStore: { studentBuyCourse },
        authStore: { token, userInfo },
        modalStore: { hide, openAlert, openWarningModal },
        userStore: { userInfoHeader, getUserInfoHeader, onAddTicketPlan, coursePeriodStatus, cancelAttendance }
    } = useStore();

    useEffect(() => {
        if (!course) return;
        let totalNotAttended = (course?.statusUserCourse === COURSE_STATUS_COMPLETE.USER_COURSE_APPLY_CERTIFICATE) ? 0 : ((course.totalTimeCourse || 0) - (course.totalTimeJoinCourse || 0));
        totalNotAttended = totalNotAttended < 1 ? 0 : totalNotAttended;
        const totalAttended = course.totalTimeJoinCourse || 0;

        const dataset = {
            label: '受講割合',
            data: [totalAttended, totalNotAttended],
            backgroundColor: [
                '#fbb03b',
                '#0077b6'
            ]
        };

        setDataPieChart({
            ...dataPieChart,
            datasets: [
                dataset
            ]
        })
    }, [course])

    const courseStatusName = useMemo(() => {
        try {
            if (!course?.type?.length) return false;
            const types = [...course.type].map(e => Number(e));

            if (types.includes(0) && types.includes(1)) {
                return '精神薬学会認定薬剤師';
            }

            if (types.includes(0) && types.includes(2)) {
                return '漢方薬・生薬認定薬剤師';
            }

            if (types.includes(0) && types.includes(3)) {
                return '小児薬物療法認定薬剤師';
            }

            return false;
        } catch (error) {
            return false
        }
    }, [course?.type])

    const courseType = useMemo(() => {
        /**
            [0] -> 研修認定薬剤師 || 研修
            [0,1] -> 精神薬学会認定薬剤師 || 精神
            [0,2] -> 漢方薬・生薬認定薬剤師 || 研修 / 小児
            [0,3] -> 小児薬物療法認定薬剤師 || 研修 / 漢方
         */
        try {
            if (!course?.type?.length) return '';
            const types = [...course.type].map(e => Number(e));

            if (types.includes(0) && types.length === 1) {
                return {
                    status: 0,
                    content: "<span class='title-box xanh'>研修</span>"
                };
            }

            if (types.length === 2 && types.includes(0) && types.includes(1)) {
                return {
                    status: 1,
                    content: "<span class='title-box xanh'>研修</span><span class='title-box tim'>精神</span>"
                };
            }

            if (types.length === 2 && types.includes(0) && types.includes(2)) {
                return {
                    status: 2,
                    content: "<span class='title-box xanh'>研修</span><span class='title-box cam'>漢方</span>"
                };
            }

            if (types.length === 2 && types.includes(0) && types.includes(3)) {
                return {
                    status: 3,
                    content: "<span class='title-box xanh'>研修</span><span class='title-box vang'>小児</span>"
                };
            }

            if(course?.themeType === THEME_TYPE.NON_CERTIFICATE) {
                return {
                    content: ''
                }
            }

            return '';
        } catch (error) {
            return ''
        }
    }, [course?.type])

    const isM3u8 = useMemo(() => {
        return course?.sampleVideo && course.sampleVideo.toLowerCase().includes('m3u8');
    }, [course]);

    const courseDetailSidebar = useMemo(() => {
        return {
            CONTENT_LIST: {
                label: 'コンテンツ一覧',
                value: 1
            },
            ...(token && course?.statusUserCourse !== null ? {
                PROGRESS_RESULT: {
                    label: '進捗と結果',
                    value: 2
                }
            } : {}),
            COURSE_INFO: {
                label: '講座情報',
                value: 3
            }
        }
    }, [course])

    const handleBuyCourse = (event) => {
        event.stopPropagation();

        const id = course?.id;
        if (!token) {
            openAlert(MSG['warning.student.apply.ticket'], () => {
                hide();
            })
        } else {
            if (userInfoHeader?.numberTicket == 0) {
                onAddTicketPlan();
            } else {
                studentBuyCourse(id).then(res => {
                    if (res?.ok) {
                        if (token) {
                            getUserInfoHeader();
                        }
                        onFetchData && onFetchData();
                    }
                });
            }
        }
    }

    const handleClickCompletionTest = (event) => {
        event.stopPropagation();
        navigate(`/course/${course?.id}/completion-test`);
    }

    const handleClickReTest = (event) => {
        event.stopPropagation();
        navigate(`/course/${course?.id}/completion-test`);
    }

    const handleShowMore = (event) => {
        event.stopPropagation();
        setShowMore(prev => !prev);
    }

    const handleViewOldCourse = (event) => {
        event.stopPropagation();
        if(course?.isOldCourse) {
            if(userInfoHeader?.coursePeriodStart && userInfoHeader?.coursePeriodEnd &&
                moment().isSameOrBefore(moment(userInfoHeader?.coursePeriodEnd), 'date')) {
                navigate(`/course/${course?.id}`);
            } else {
                openAlert(MSG['error.view_old_course'], () => {
                    hide();
                }, 'auto', '閉じる');
            }
        }
    }

    const handleNavigateCourseDetail = (event) => {
        event.stopPropagation();
        if(token) {
            if(isShowCourseStatus(token, course)) {
                navigate(`/course/${course?.id}`);
            } else {
                handleViewOldCourse(event);
            }
        }
    }

    const handleViewContentList = () => {
        navigate(`/course/${course?.id}`);
    }

    const handleCancelAttendance = async () => {
        openWarningModal(MSG['cancel.attendance.update.confirm'], async () => {
            const res = await flowResult(cancelAttendance(course?.id));
            if (res) {
                handleSearch && handleSearch();
                getUserInfoHeader();
                ReactNotifications('success', MSG['inform.success.update']);
            }
        })
    }

    const courseRes = useMemo(() => {
        const courseDetail = toJS(course);
        const status = courseDetail?.statusUserCourse;
        let name = '';
        let color = '';
        let backgroundColor = '';
        switch (status) {
        case COURSE_STATUS_COMPLETE.USER_COURSE_NOT_ATTENDED: // 0
        case COURSE_STATUS_COMPLETE.USER_COURSE_ATTENDING: // 1
        case COURSE_STATUS_COMPLETE.USER_COURSE_NOT_DO_TEST: // 2
            name = '受講中';
            color = '#d96f04';
            backgroundColor = '#ffe9d5';
            break;
        case COURSE_STATUS_COMPLETE.USER_COURSE_APPLY_CERTIFICATE: // 3
            name = '受講完了';
            color = '#00811d';
            backgroundColor = '#e4f1e7';
            break;
        default:
            name = '未受講';
            color = '#4d4d4d';
            backgroundColor = '#d5d5d566';
            break;
        }

        return { name, color, backgroundColor };
    }, [course])

    const options = {
        plugins: {
            legend: {
                display: true,
                position: 'right',
                labels: {
                    boxWidth: 10,
                    boxHeight: 10,
                    padding: 5
                }
            }
        }
    };

    const isShowAchievement = course?.completionDate !== null;
    const isShowPieChart = course?.statusUserCourse !== null && course?.statusUserCourse !== undefined;
    const isShowOverlay = useMemo(() => {
        if (extractContent(course?.lecturerIntroduction || '').length > 10 ||
            extractContent(course?.description || '').length > 10 || isShowPieChart || isShowAchievement) {
            return true;
        }

        return false;
    }, [course, isShowPieChart, isShowAchievement])

    return (
        <div className='course-card-wrapper'>
            <div className='course-card-container'>
                <div className="img-course">
                    {
                        course?.sampleVideo ? (
                            <VideoPlayer
                                options={{
                                    sources: [
                                        {
                                            src: course?.sampleVideo,
                                            type: isM3u8 ? 'application/x-mpegURL' : 'video/mp4'
                                        }
                                    ]
                                }}
                                width={320}
                                height={180}
                                disableForward={false}
                                onRunningVideo={() => setIsShowTitleVideoSample(false)}
                            />
                        ) : (
                            <div className='not-show-video'>
                                <img src={course?.thumbnail || '/images/img-1.png'} alt='' style={{'height': '100%', 'objectFit': 'fill'}}/>
                                <p>
                                    <Link to={SYSTEM_PATH.LOGIN}>ログイン</Link>後にご覧いただけます。
                                </p>
                            </div>
                        )
                    }
                    {
                        token && isShowTitleVideoSample && <p className="title-course-sample">サンプルコンテンツ</p>
                    }
                </div>

                <div className={classNames('course-content', !token && 'cursor-pointer-none')} onClick={handleNavigateCourseDetail}>
                    <p className="title-category line-clamp-1">{course?.themeName}</p>

                    <h3 className={classNames('title-3')}>
                        <span className={classNames('course-card-name', course?.isNew && 'icon-lecture-new')}>{course?.name}</span>
                        {
                            isShowCourseStatus(token, course) && (
                                <span className='item-course-status' style={{ 
                                    background: courseRes.backgroundColor,
                                    color: courseRes.color
                                }}>
                                    {
                                        courseRes.name === '受講完了' ? (
                                            <i className='fa-solid fa-check' style={{ color: courseRes.color }}></i>
                                        ) : (
                                            <div className='dot' style={{ backgroundColor: courseRes.color }}></div>
                                        )
                                    }
                                    {courseRes.name}
                                </span>
                            )
                        }
                    </h3>

                    <div className='d-flex align-items-center course-tag-container'>
                        <div className='list-note'>
                            <p dangerouslySetInnerHTML={{ __html: courseType.content }} 
                                className='rows-center mg-b-0 d-flex flex-gap-10 align-items-center justify-content-center flex-wrap'>
                            </p>
                        </div>
                    </div>
                    <div className='d-flex align-items-center course-tag-container mg-t-10'>
                        {
                            course?.isSpecifiedCourse && (
                                <div className='course-specified-tag'>
                                    <img src='/images/icon-specified-course.svg' alt='' />
                                    研修指定講座
                                </div>
                            )
                        }
                    </div>

                    <div className="clock-video">
                        <ul>
                            <li className='li-0 d-flex align-items-center flex-gap-5'>
                                <i className='fa-regular fa-md fa-calendar' style={{ color: '#afb0b5' }}></i>
                                <span style={{ color: '#363946', fontWeight: '700' }}>{moment(course?.publicDate).format(DATE_YMD)}</span>
                            </li>
                            <li className='li-0 d-flex align-items-center flex-gap-5'>
                                <img className='mg-0' src='/images/icon2.svg' alt='' style={{ height: '15px', width: '15px' }} />
                                <span style={{ color: '#363946', fontWeight: '700' }}>{`${numberWithCommas(course?.totalLecture)}コンテンツ`}</span>
                            </li>
                            <li className='li-1 d-flex align-items-center flex-gap-5'>
                                <img className='mg-0' src='/images/icon1.svg' alt='' style={{ height: '15px', width: '15px' }} />
                                <span style={{ color: '#363946', fontWeight: '700' }}>約 {convertVideoDuration(course?.totalTime)} 分</span>
                            </li>
                            {
                                course?.level && (
                                    <li className='li-1 d-flex align-items-center flex-gap-5'>
                                        <img src='/images/icon-level-course.svg' alt='' style={{ color: '#afb0b5' }}/>
                                        <span style={{ color: '#363946', fontWeight: '700' }}>{LEVEL_COURSE_STRING[course.level]}</span>
                                    </li>
                                )
                            }
                        </ul>
                    </div>
                </div>

                <div className={classNames('course-action-btn', course?.isCancelAttendance && 'is-cancel-attendance')}>
                    {
                        (course?.statusUserCourse === COURSE_STATUS_COMPLETE.USER_COURSE_ATTENDING || course?.statusUserCourse === COURSE_STATUS_COMPLETE.USER_COURSE_NOT_ATTENDED) && (
                            <button 
                                className={classNames(course?.isCancelAttendance && 'is-cancel-attendance')}
                                type='button' 
                                onClick={handleViewContentList}
                            >
                                受講を続ける
                            </button>
                        )
                    }
                    {
                        isShowJoinCourse(token, course, userInfo) &&
                        <button 
                            type='button' 
                            onClick={handleBuyCourse} 
                            className={classNames('join-course join-course-btn', course?.isCancelAttendance && 'is-cancel-attendance')}
                            {
                                ...(course?.isFreeCourse && userInfoHeader?.isCourseTicket && userInfoHeader?.isFreeTicket && !userInfoHeader?.remainingFreeAmountTicket) ? {
                                    disabled: true,
                                    'data-bs-toggle': 'tooltip', 
                                    'data-bs-placement': 'bottom',
                                    'title': '自由選択単位数の上限に達しているため、この講座を受講することはできません。'
                                } : {}
                            }
                        >
                            受講ボタン
                        </button>
                    }
                    {
                        isViewOldCourse(token, course, userInfo) && userInfoHeader?.numberTicket > 0 &&
                        <button type='button' onClick={handleViewOldCourse} className={classNames('join-course', course?.isCancelAttendance && 'is-cancel-attendance')}>
                            講座視聴
                        </button>
                    }
                    {
                        isShowCompletionTest(token, course, coursePeriodStatus) &&
                        <button type='button' onClick={handleClickCompletionTest} className={classNames('join-course', course?.isCancelAttendance && 'is-cancel-attendance')}>
                            修了テスト
                        </button>
                    }
                    {
                        isShowReTest(token, course, coursePeriodStatus) &&
                        <button type='button' onClick={handleClickReTest} className={classNames('join-course', course?.isCancelAttendance && 'is-cancel-attendance')}>
                            再テストの実施
                        </button>
                    }

                    {
                        isShowCancelButton(token, course) && (
                            <div
                                onClick={handleCancelAttendance}
                                className={classNames('cancel-attendance-btn', course?.isCancelAttendance && 'is-cancel-attendance')}
                            >
                                    受講をキャンセル
                            </div>
                        )
                    }

                    <div 
                        onClick={() => setIsShowCourseDetail(!isShowCourseDetail)}
                        className={classNames('d-flex align-items-center flex-gap-10 show-course-detail-btn', isShowCourseDetail && 'toggled', course?.isCancelAttendance && 'is-cancel-attendance', !token && 'dont-login')}>
                        詳細を見る
                        <img className='expand-icon' src='/images/icon-arrow-down.svg' alt='' />
                    </div>
                </div>
            </div>

            {
                isShowCourseDetail && (
                    <div className='course-card-container course-detail' style={{ margin: 0 }}>
                        <div className="img-course" style={{ visibility: 'hidden' }}>
                            {
                                course?.sampleVideo ? (
                                    <VideoPlayer
                                        options={{
                                            sources: [
                                                {
                                                    src: course?.sampleVideo,
                                                    type: isM3u8 ? 'application/x-mpegURL' : 'video/mp4'
                                                }
                                            ]
                                        }}
                                        width={320}
                                        height={180}
                                        disableForward={false}
                                        onRunningVideo={() => setIsShowTitleVideoSample(false)}
                                    />
                                ) : (
                                    <div className='not-show-video'>
                                        <img src={course?.thumbnail || '/images/img-1.png'} alt='' style={{'height': '100%', 'objectFit': 'fill'}}/>
                                        <p>
                                            <Link to={SYSTEM_PATH.LOGIN}>ログイン</Link>後にご覧いただけます。
                                        </p>
                                    </div>
                                )
                            }
                            {
                                isShowTitleVideoSample && <p className="title-course-sample">サンプルコンテンツ</p>
                            }
                        </div>
                        <div className='course-card-detail'>
                            <div className='d-flex align-items-center sidebar'>
                                {
                                    Object.keys(courseDetailSidebar).map((item, index) => {
                                        return (
                                            <div
                                                key={index} 
                                                onClick={() => {
                                                    if (currentTabCourseDetail !== courseDetailSidebar[item].value) {
                                                        setCurrentTabCourseDetail(courseDetailSidebar[item].value);
                                                    }
                                                }}
                                                className={classNames('sidebar-item', currentTabCourseDetail === courseDetailSidebar[item].value && 'active')}
                                            >{courseDetailSidebar[item].label}</div>
                                        )
                                        
                                    })
                                }
                            </div>
                            <div className='course-detail-container'>
                                {
                                    currentTabCourseDetail === courseDetailSidebar?.CONTENT_LIST?.value && (
                                        <div className='content-list-container'>
                                            {
                                                course?.lecturesOfCourse && course.lecturesOfCourse?.length > 0 &&
                                                course.lecturesOfCourse.slice(0, 20).map((lecture, index) => (
                                                    <ContentListItem  
                                                        key={index}
                                                        id={lecture.id}
                                                        title={lecture.name}
                                                        lastTimeViewed={lecture.user_lecture_last_time_viewed}
                                                        videoLength={lecture.video_length}
                                                        status={lecture.status}
                                                        isEnableViewDetail={token && course?.statusUserCourse !== null}
                                                    />
                                                ))
                                            }
                                            {
                                                course?.lecturesOfCourse && course.lecturesOfCourse?.length > 20 && (
                                                    <div
                                                        onClick={() => navigate(`/course/${course?.id}`)}
                                                        className='content-view-more d-flex align-items-center justify-content-center flex-gap-5'>
                                                        <span>すべてのコンテンツを見る</span>
                                                        <i className='fa-solid fa-sm fa-angles-right'></i>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    )
                                }
                                {
                                    currentTabCourseDetail === courseDetailSidebar?.PROGRESS_RESULT?.value && (
                                        <div className='progress-result-container'>
                                            {
                                                token && (
                                                    <div className='row mg-t-10 chart-theme'>
                                                        {
                                                            isShowAchievement && (
                                                                <div className="col-md-6 col-sm-6 col-12 c1">
                                                                    <h3 className='title-intro text-center'>正答率</h3>
                                                                    <div className="chart-area chart-area1" style={{ width: 300, height: 200, margin: 'auto', paddingTop: 20 }}>
                                                                        <div className='chart-area1-item' style={{ width: 150, height: 150, margin: 'auto' }}>
                                                                            <CircularProgressbar
                                                                                strokeWidth={12}
                                                                                value={course?.achievement || 0}
                                                                                maxValue={100} text={`${course?.achievement || 0}%`}
                                                                                styles={buildStyles({
                                                                                    pathColor: '#FCB03B', textColor:
                                                                                        '#FCB03B', fontWeigth: 'bold'
                                                                                })} />
                                                                        </div>
                                                                        {course?.completionDate &&
                                                                            <p className="desc text-center"><span>実施日: </span>
                                                                                {moment(course?.completionDate).format(DATE_TIME_YMD)}
                                                                            </p>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                        {
                                                            isShowPieChart && (
                                                                <div className="col-md-6 col-sm-6 col-12">
                                                                    <h3 className='title-intro pd-l-60'>受講割合</h3>
                                                                    <div className="chart-area" style={{ width: 350, height: 300, margin: 'auto', marginTop: -55 }}>
                                                                        <Pie data={dataPieChart} options={options} width={250}
                                                                            height={250} />
                                                                    </div>
                                                                </div>
                                                            )

                                                        }
                                                    </div>
                                                )
                                            }
                                        </div>
                                    )    
                                }
                                {
                                    currentTabCourseDetail === courseDetailSidebar?.COURSE_INFO?.value && (
                                        <div className='course-info-container'>
                                            {
                                                course?.description && (
                                                    <>
                                                        <h3 className='title-intro mg-t-10'>講座内容</h3>
                                                        <p className="dessc-2">{ReactHtmlParser(course.description || '')}</p>
                                                    </>
                                                )
                                            }
                                            {
                                                course?.lecturerIntroduction && (
                                                    <>
                                                        <h3 className='title-intro mg-t-10'>講師紹介</h3>
                                                        <p className="dessc-2">{ReactHtmlParser(course?.lecturerIntroduction || '')}</p>
                                                    </>
                                                )
                                            }
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default CourseCard;

const ContentListItem = ({ id, title, lastTimeViewed, videoLength, status, isEnableViewDetail }) => {
    // ohter
    const navigate = useNavigate();

    let statusText = '未視聴';
    let statusColor = '#6D757D';

    if (lastTimeViewed > 0 && lastTimeViewed < videoLength) {
        statusText = '視聴中';
        statusColor = '#FFC108'
    }

    if (lastTimeViewed > 0 && lastTimeViewed === videoLength || status === 2) {
        statusText = '視聴済';
        statusColor = '#28A745'
    }

    // function
    const handleNavigateLectureDetail = () => {
        if (!isEnableViewDetail) return;
        navigate(`/lecture/${id}`);
    }
    return (
        <div
            onClick={handleNavigateLectureDetail}
            className={classNames('content-list-item', isEnableViewDetail && 'cursor-pointer')}>
            <span className='title line-clamp-1'>{title}</span>
            <span className='info flex-none'>
                <div className='video-length'>{formatTimeFromVideoLength(videoLength)}</div>
                {
                    isEnableViewDetail && (
                        <div style={{ color: statusColor }} className='lecture-status d-flex align-items-center flex-gap-5'>
                            {
                                statusText === '視聴済' ? (
                                    <i className='fa-solid fa-check' style={{ color: statusColor }}></i>
                                ) : (
                                    <div className='d-flex align-items-center justify-content-center' style={{ width: '12px', height: '12px' }}>
                                        <div className='dot' style={{ backgroundColor: statusColor }}></div>
                                    </div>
                                )
                            }
                            {statusText}
                        </div>
                    )
                }
            </span>
        </div>
    )
}