import { observer } from 'mobx-react';
import { useEffect, useMemo, useState } from 'react';
import { DATE_YMD_ISO, DOWNLOAD_ATTENDANCE_HISTORY_TYPE, USER_TYPE } from '../../core/configs/constants';
import { useStore } from '../../core/utils/hook';
import ProfileSideBar from '../Profile/components/ProfileSideBar';
import AttendanceHistoryItem from './components/AttendanceHistoryItem';
import Pagination from '../Courses/components/Pagination';
import { handleDownloadFile } from '../../core/utils/file';
import moment from 'moment';
import { MutilCalendarCustom } from '../../components';

function AttendanceHistoryScreen() {
    const [params, setParams] = useState({});
    const [range, setRange] = useState();

    const {
        authStore: { userInfo },
        apiStore: { showLoading, hideLoading },
        courseStore: { cleanAttendanceHistory, attendanceHistories, pagingAttendanceHistory, getAttendanceHistories, totalStatusApplyAttendance,
            handleToggleCheckAll, handleToggleCheckItem, selectedAttendances, cleanSelectedAttendance },
        modalStore: { openErrorModal }
    } = useStore();

    useEffect(() => {
        handleSearch();
        return () => {
            cleanAttendanceHistory();
        }
    }, [])

    const fetchData = (init = {}) => {
        getAttendanceHistories({ ...params, ...init });
    }

    const handleSearch = () => {
        let fromDate = null, toDate = null;
        if (range?.[0]?.startDate && !isNaN(range?.[0]?.startDate) && range?.[0]?.endDate && !isNaN(range?.[0]?.endDate)) {
            fromDate = moment(range[0].startDate).format(DATE_YMD_ISO);
            toDate = moment(range[0].endDate).format(DATE_YMD_ISO);
        }

        setParams(prev => {
            return { ...prev, fromDate, toDate };
        })
        cleanSelectedAttendance();
        fetchData({ fromDate, toDate });
    }

    const handleChangeStatus = (key) => (event) => {
        const val = event.target.checked;
        setParams(prev => {
            let status = [...(prev.status || [])];
            if (val) {
                status.push(key);
            } else {
                status = status.filter(item => item !== key)
            }
            return { ...prev, status };
        })
    }

    const selectedIds = useMemo(() => {
        if (selectedAttendances.length > 0) {
            return selectedAttendances.map(item => item.courseId);
        } else {
            return attendanceHistories.map(item => item.courseId);
        }
    }, [attendanceHistories])

    const isCheckAll = useMemo(() => {
        const selectedRows = (attendanceHistories || []).filter(item => item.isChecked);
        return attendanceHistories?.length > 0 && selectedRows.length === attendanceHistories?.length
    }, [attendanceHistories])

    const isHaveCourseCanCancelAttendance = useMemo(() => {
        return attendanceHistories.some(item => item.isCancelAttendance);
    }, [attendanceHistories])

    const handleAfterDownload = (msg) => {
        if (msg) {
            openErrorModal('エラー', msg);
        }
        hideLoading();
    }

    const handleExportAll = () => {
        const objDate = converParamDate();
        showLoading();
        handleDownloadFile({
            url: 'course/attendance/export-pdf',
            data: {
                typeExport: DOWNLOAD_ATTENDANCE_HISTORY_TYPE.ALL, userIds: [userInfo?.id], courseIds: selectedIds, searchByDate: {
                    fromDate: objDate.fromDate,
                    toDate: objDate.toDate
                }
            },
            cb: handleAfterDownload
        })
    }

    const handleExportSpecial = (courseId) => {
        const objDate = converParamDate();
        showLoading();
        handleDownloadFile({
            url: 'course/attendance/export-pdf',
            data: { typeExport: DOWNLOAD_ATTENDANCE_HISTORY_TYPE.SPECIAL, userIds: [userInfo?.id], courseIds: [courseId], searchByDate: {
                fromDate: objDate.fromDate,
                toDate: objDate.toDate
            } },
            cb: handleAfterDownload
        })
    }

    const onFetchData = (tableData = {}, picker = false) => {
        if (!tableData.fromDate && range?.[0]?.startDate && !picker) {
            const fromDate = moment(range[0].startDate).format(DATE_YMD_ISO)
            const toDate = moment(range[0].endDate).format(DATE_YMD_ISO)
            tableData.fromDate = fromDate;
            tableData.toDate = toDate;
        }
    };

    const handleChangeDate = (date) => {
        if (date.selection) {
            if (date.selection?.startDate && !isNaN(date.selection?.startDate) && date.selection?.endDate && !isNaN(date.selection?.endDate)) {
                const fromDate = moment(date.selection.startDate).format(DATE_YMD_ISO);
                const toDate = moment(date.selection.endDate).format(DATE_YMD_ISO);
                setRange(date.selection ? [date.selection] : undefined);
                onFetchData({ fromDate, toDate });
            } else {
                setRange(date.selection ? [date.selection] : undefined);
            }
        } else {
            setRange(date);
            onFetchData({}, true);
        }
    }

    const converParamDate = () => {
        let fromDate = null, toDate = null;
        if (range?.[0]?.startDate && !isNaN(range?.[0]?.startDate) && range?.[0]?.endDate && !isNaN(range?.[0]?.endDate)) {
            fromDate = moment(range[0].startDate).format(DATE_YMD_ISO);
            toDate = moment(range[0].endDate).format(DATE_YMD_ISO);
        }

        return { fromDate: fromDate, toDate: toDate };
    }

    if (!userInfo?.roleId || [USER_TYPE.GROUP_MANAGER].includes(userInfo?.roleId)) {
        return null;
    }

    return (
        <div id="main" className="wrapper main-student-list main-Attended-couse attendance-history attendance-history-screen">
            <div className="bres-cus">
                <div className="container">
                    <ul>
                        <li>
                            <a href="/">
                                <img src="/images/icon-home.svg" alt="" />
                            </a>
                        </li>
                        <li className="dash">\</li>
                        <li>受講履歴</li>
                    </ul>
                </div>
            </div>
            <section className="top-student-list">
                <div className="container">
                    <div className="row">
                        {/* side bar */}
                        {/* <div className="col-md-2 col-sm-2 col-12">
                            <ProfileSideBar />
                        </div> */}

                        <div className="col-12">
                            <div className="content-Attended-couse ">
                                {/* <h1 className="title-primary">受講履歴</h1> */}
                                {/* filter */}
                                <div className="content-top-student pd-tb-25">
                                    <div className='row'>
                                        <div className="col-md-12 col-sm-12 col-12">
                                            <div className='item item-22 custom-item-22 mg-b-15'>
                                                指定した期間中の受講履歴が確認できます
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 col-sm-12 col-12 d-flex align-items-center">
                                            {/* <label htmlFor="lst-period" className='col-2-5'>対象期間</label> */}
                                            {/* <select onChange={handleChangePeriod} className='select-status-student' id="lst-period"> */}
                                            {/* <CoursePeriodOptions />
                                                </select> */}
                                            <div className='item item-22 custom-item-22 mg-t-0 ignore-css'>
                                                <div className='mg-r-15'>
                                                    <label htmlFor="" className='mg-t-10'>対象期間</label>
                                                </div>
                                                <div className='col-xl-10 col-12'>
                                                    <MutilCalendarCustom
                                                        ranges={range}
                                                        displayMode={'dateRange'}
                                                        onChange={handleChangeDate}
                                                        isEditInput={false}
                                                        isFirstDate={false}
                                                    />
                                                </div>
                                            </div>
                                            {/* <input type="submit" className='submit-search' value="検索" onClick={handleSearch} /> */}
                                        </div>

                                        <div className="col-md-12 col-sm-12 col-12">
                                            <div className="item item-22 custom-item-22 mg-tb-0 d-flex align-items-center search-status">
                                                <label htmlFor="">ステータス</label>
                                                <ul className="list-check align-items-center pd-l-0">
                                                    {/* <li className='li-0'>
                                                        <input id="st-0" type="checkbox"  onChange={handleChangeStatus(0)} />
                                                        <label className='cursor-pointer' htmlFor='st-0'>未受講</label>
                                                    </li> */}
                                                    <li className='li-1 min-width-120'>
                                                        <input id="st-1" type="checkbox" onChange={handleChangeStatus(1)} />
                                                        <label className='cursor-pointer' htmlFor='st-1'>受講中</label>
                                                    </li>
                                                    <li className='li-0 min-width-120'>
                                                        <input id="st-2" type="checkbox" onChange={handleChangeStatus(2)} />
                                                        <label className='cursor-pointer' htmlFor='st-2'>受講完了</label>
                                                    </li>
                                                    {/* <li className='li-1'>
                                                        <input id="st-3" type="checkbox" onChange={handleChangeStatus(3)} />
                                                        <label className='cursor-pointer' htmlFor='st-3'>期限切れ</label>
                                                    </li> */}
                                                    <input 
                                                        type="submit" 
                                                        className='submit-search' 
                                                        style={{marginLeft: 'auto'}} 
                                                        value="検索" onClick={handleSearch} />
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <section className="content-student-list">
                                    <div className="row">
                                        <div className="col-lg-9 col-12 d-flex justify-content-center flex-column">
                                            <div className='d-flex flex-gap-10 flex-wrap align-items-center'>
                                                <h3 className="titl-4 mg-t-0">①単位申請中で{totalStatusApplyAttendance?.totalApplying || 0}件</h3>
                                                <h3 className="titl-4 mg-t-0">②単位申請済で{totalStatusApplyAttendance?.totalApplied || 0}件</h3>
                                            </div>
                                            <div className='titl-4 fw-bold text-danger mg-t-5'>※下の表の「テスト結果」をクリックいただくと、テスト結果の詳細が表示されます。</div>
                                        </div>
                                        {
                                            attendanceHistories?.length > 0 &&
                                            <div className="col-lg-3 col-12">
                                                <div className="filter-list">
                                                    <ul>
                                                        <li>
                                                            <a className='btn-export btn-export1 mg-0' href="#" onClick={handleExportAll} >
                                                                申請単位一覧
                                                            </a>
                                                        </li>
                                                        {/* {
                                                            selectedIds.length > 0 && 
                                                            <li>
                                                                <a className='btn-export btn-export1' role='button' onClick={handleExportAll}>
                                                                    <i className="fa fa-file-pdf-o" aria-hidden="true"></i>受講状況一覧PDFボタン
                                                                </a>
                                                            </li>
                                                        }
                                                        {
                                                            selectedIds.length > 0 && 
                                                            <li>
                                                                <a className='btn-export' role='button'
                                                                    onClick={handleExportSpecial}>
                                                                    <i className="fa fa-file-pdf-o" aria-hidden="true"></i>修了証PDFボタン（専門認定単位用)
                                                                </a>
                                                            </li>
                                                        } */}
                                                    </ul>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="table-table">
                                        <table>
                                            <tr>
                                                <td>
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <input disabled={!(attendanceHistories || []).length}
                                                            checked={isCheckAll} onChange={handleToggleCheckAll} type="checkbox" />
                                                    </div>
                                                </td>
                                                <td>講座名</td>
                                                {/* <td>テーマ名</td> */}
                                                <td>受講ステータス</td>
                                                <td>受講完了日</td>
                                                <td>テスト結果</td>
                                                {/* <td>進捗（％)</td> */}
                                                {/* <td>受講状況</td>
                                                <td>認定種別</td> */}
                                                <td>単位申請状況</td>
                                                <td>申請単位の認定種別</td>
                                                <td>修了証</td>
                                                {
                                                    isHaveCourseCanCancelAttendance && (<td></td>)
                                                }
                                            </tr>
                                            {
                                                (attendanceHistories || []).map((item, index) => {
                                                    return <AttendanceHistoryItem
                                                        key={index}
                                                        item={item}
                                                        onToggleCheck={handleToggleCheckItem}
                                                        isHaveCourseCanCancelAttendance={isHaveCourseCanCancelAttendance}
                                                        fetchData={fetchData}
                                                        onSelectRow={handleExportSpecial} />
                                                })
                                            }
                                        </table>
                                        {!(attendanceHistories || []).length &&
                                            <div className='no-data w-100'>
                                                <p>データはありません</p>
                                            </div>
                                        }
                                    </div>
                                    <Pagination hideTotal paging={pagingAttendanceHistory} onFetch={fetchData} />
                                </section>
                                <div className='mg-t-20'>
                                    <p className='fw-bold text-danger mg-0'>
                                        {/* eslint-disable-next-line max-len */}
                                        ・研修認定薬剤師、小児薬物療法認定薬剤師（更新のみ）、漢方薬・生薬認定薬剤師（更新のみ）の単位は、受講完了日の1ヶ月以内にPECS へ申請いたします。
                                    </p>
                                    <p className='fw-bold text-danger'>
                                        単位申請と同時に上記表の「単位申請状況」欄が「単位申請済」の表記となり、翌日にPECSへ単位反映されます。なお、単位取得日は受講完了日となりますので予めご了承ください。
                                    </p>
                                    <div className='list-note'>
                                        <p>
                                            <span className='title-box xanh mg-r-15'>研修</span>
                                            <span className='content'>→ 研修認定薬剤師。</span>
                                        </p>
                                        <p>
                                            <span className='title-box vang mg-r-15'>小児</span>
                                            <span className='content'>→ 小児薬物療法認定薬剤師（更新のみ）。更新者は小児薬物療法認定薬剤師の単位、更新者でない場合は研修認定薬剤師の単位として申請されます。</span>
                                        </p>
                                        <p>
                                            <span className='title-box cam mg-r-15'>漢方</span>
                                            <span className='content'>→ 漢方薬・生薬認定薬剤師（更新のみ）。更新者は漢方薬・生薬認定薬剤師の単位、更新者でない場合は研修認定薬剤師の単位として申請されます。</span>
                                        </p>
                                        <p>
                                            <span className='title-box tim mg-r-15'>精神</span>
                                            <span className='content'>→ 精神薬学会認定薬剤師の認定申請または更新に必要な受講修了証です。認定申請・更新の手続きは受講者自身で行う必要がありますので、
                                                <a href={`${window.location.origin}/files/UserManual.pdf`} target='_blank'
                                                    className='text-primary'>利用マニュアル</a> をご確認ください。
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default observer(AttendanceHistoryScreen);
