import classNames from 'classnames';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Table } from '../../components';
import { COURSE_BY_STUDENT_STATUS, CREDIT_APPLICATION_STATUS, DATE_YMD } from '../../core/configs/constants';
import { useQuery, useStore } from '../../core/utils/hook';
import moment from 'moment';

const CourseListByStudentScreen = observer(({ isComponent = false }) => {

    // params, query
    const { id } = useParams();
    const query = useQuery();
    const status = query.get('status');

    // store
    const { groupAdminStore: { getCourseListByStudent, courseListByStudent } } = useStore();

    // state
    const { register, watch } = useForm({ defaultValues: { status: status ? Number(status) : COURSE_BY_STUDENT_STATUS.ALL.key } });

    const watchStatus = watch('status');

    // lifecycle
    useEffect(() => {
        getCourseListByStudent(id, { status: watchStatus });
    }, [watchStatus])

    // columns
    const columns = [
        {
            Header: 'No.',
            accessor: '',
            disableSortBy: true,
            Cell: ({ row: { original, index } }) => {
                return (
                    <div>
                        {index + 1}
                    </div>
                );
            },
            width: '5%'
        },
        {
            Header: '講座ID',
            accessor: '',
            disableSortBy: true,
            Cell: ({ row: { original, index } }) => {
                return (
                    <div>
                        {original?.code}
                    </div>
                );
            },
            width: '10%'
        },
        {
            Header: '講座名',
            accessor: 'name',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        <Link to={`/course/${original?.courseId}`}>{original?.name}</Link>
                    </div>
                );
            },
            width: '20%'
        },
        {
            Header: '講座受講開始日',
            accessor: 'coursePeriodStart',
            disableSortBy: true,

            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {original?.coursePeriodStart ? moment(original.coursePeriodStart).format(DATE_YMD) : null}
                    </div>
                );
            },
            width: '12%'
        },
        {
            Header: '受講終了日',
            accessor: 'coursePeriodEnd',
            disableSortBy: true,

            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {original?.coursePeriodEnd ? moment(original.coursePeriodEnd).format(DATE_YMD) : null}
                    </div>
                );
            },
            width: '12%'
        },
        {
            Header: '受講率',
            accessor: 'attendanceRate',
            disableSortBy: true,

            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {original?.attendanceRate ? original?.attendanceRate > 100 ? 100 : original?.attendanceRate : 0}%
                    </div>
                );
            },
            width: '10%'
        },
        {
            Header: '初回正答率',
            accessor: 'achievementAverage',
            disableSortBy: true,

            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {original?.achievementAverage !== null ? `${Math.floor(original?.achievementAverage)}%` : ''}
                    </div>
                );
            },
            width: '10%'
        },
        {
            Header: 'ステータス',
            accessor: 'status',
            disableSortBy: true,

            Cell: ({ row: { original } }) => {
                // eslint-disable-next-line max-len
                const key = Object.keys(COURSE_BY_STUDENT_STATUS).filter(e => COURSE_BY_STUDENT_STATUS[e].value?.includes(original?.status))?.[0] ?? null;
                return (
                    <div>
                        <div className={classNames(
                            key !== null ?
                                COURSE_BY_STUDENT_STATUS[key].key === COURSE_BY_STUDENT_STATUS.USER_COURSE_ATTENDING.key 
                                    ? 'course-attending'
                                    : COURSE_BY_STUDENT_STATUS[key].key === COURSE_BY_STUDENT_STATUS.USER_COURSE_COMPLETE.key
                                        ? 'course-completed'
                                        : ''
                                : ''
                        )}>
                            {
                                key !== null &&
                                COURSE_BY_STUDENT_STATUS[key].label
                            }
                        </div>
                    </div>
                );
            },
            width: '10%'
        },
        {
            Header: '単位申請状況',
            accessor: '',
            disableSortBy: true,

            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {
                            original?.isNonCertificate ? '-' :
                                CREDIT_APPLICATION_STATUS.COMPLETED.value.includes(original?.status) ? 
                                    original?.isAppliedCert ?
                                        CREDIT_APPLICATION_STATUS.COMPLETED.label
                                        : CREDIT_APPLICATION_STATUS.NO_APPLY.label
                                    : CREDIT_APPLICATION_STATUS.NOT_COMPLETED.label
                        }
                    </div>
                );
            },
            width: '11%'
        }
    ]

    return(
        <div id='main' className='wrapper main-student-list main-view-student-detail main-content-home-2 pd-b-50'>
            {
                // !isComponent &&
                // <div className='bres-cus'>
                //     <div className='container'>
                //         <ul>
                //             <li>
                //                 <Link to={SYSTEM_PATH.HOME}><img src='/images/icon-home.svg' alt='' /></Link>
                //             </li>
                //             {/* <li className='dash'>\</li>
                //             <li>ログイン</li> */}
                //         </ul>
                //     </div>
                // </div>
            }
            <section className='content-student-list'>
                <div className='container'>
                    {
                        !isComponent &&
                        <h1 className='title-primary mg-b-25'>受講者 #{courseListByStudent?.studentCode} 別の受講講座リスト</h1>
                    }
                    <div className='row'>
                        <div className='col-md-12 col-sm-12 col-12'>
                            <div className='filter-2 mg-t-0'>
                                <span> ステータス</span> <select {...register('status')}>
                                    {
                                        Object.keys(COURSE_BY_STUDENT_STATUS).filter(key => !COURSE_BY_STUDENT_STATUS[key].noSearch).map(e => 
                                            <option key={COURSE_BY_STUDENT_STATUS[e].key} 
                                                value={COURSE_BY_STUDENT_STATUS[e].key}>{COURSE_BY_STUDENT_STATUS[e].label}</option>)
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                    <Table
                        columns={columns}
                        data={courseListByStudent?.listCourse || []}
                        disablePaging={true}
                        disableSelectionPageSize={true}
                        scrollHorizontalWidth={'1200px'}
                        className='lst-attendance-table mg-t-35'
                    />
                </div>
            </section>
        </div>

    )
})

export default CourseListByStudentScreen;