/* eslint-disable max-len */

import React, { useState } from 'react';
import { useStore } from '../../core/utils/hook';
import { MSG, SYSTEM_PATH } from '../../core/configs/constants';
import { Link, useNavigate } from 'react-router-dom';
import { onLoadSwiper } from '../../core/utils/main';
import { observer } from 'mobx-react-lite';
import { FormatNumber } from '../../components';
import CourseListForTicketPlanModal from './CourseListForTicketPlanModal';


const TicketPlanListScreen = observer(() => {
    // store
    const { 
        authStore: { token }, 
        ticketStore: { getAllTickets, tickets },
        modalStore: { show, hide, openWarningModal }
    } = useStore();

    const navigate = useNavigate()

    React.useEffect(() => {
        getAllTickets({ status: 1 });
    }, [])

    React.useEffect(() => {
        if(tickets?.length > 0) {
            onLoadSwiper('slider-price', '.swiper-scrollbar-price', '#navigation-right-1', '#navigation-left-1', 392, false, false, 4, 2.5, 1.25, 24);
        }
    }, [tickets])

    // function
    const onShowCourseListForTicketPlan = (ticket) => {
        show({
            id: 'modal-course-list-for-ticketPlan',
            isOpen: true,
            header: '受講できる講座の一覧',
            onCancel: () => hide(),
            children: (
                <CourseListForTicketPlanModal ticket={ticket} />
            ),
            type: 'medium min-width-800'
        })
    }

    const verifyCourseOfTicket = (ticket) => {
        if(!ticket.allowBuy) return;

        if(ticket.isTakenCourse && ticket.courseAttended > 0) {
            openWarningModal(
                <><span className='text-decoration-underline'>{ticket.courseAttended}</span>つの講座は既に受講完了されているため、この受講プランを購入すると{ticket.courseAttended}単位分 がプラン数から差し引かれます。このプランを購入しますか?</>
                , () => navigateToPayment(ticket), '確認', 'small', 'OK', 'キャンセル', 'modal-footer-reverse');
        } else {
            navigateToPayment(ticket);
        }
    }

    const navigateToPayment = (ticket) => {
        // format state
        delete ticket?.courses;

        const state = {
            ...ticket
        }
        navigate(SYSTEM_PATH.PAYMENT, { state: {...state} });
    }

    return (
        <div id="main" className="wrapper main-execute-completion main-Purchase-ticket">
            <div className="bres-cus">
                <div className="container">
                    <ul>
                        <li>
                            <a href={SYSTEM_PATH.HOME}><img src="/images/icon-home.svg" alt="" /></a>
                        </li>
                        <li className="dash">\</li>
                        <li>受講プランのご案内</li>
                    </ul>
                </div>
            </div>

            <section className='Price-home'>
                <div className='container'>
                    <div className='d-flex flex-gap-10 flex-wrap align-items-end mg-t-25'>
                        <h2 className='title-primary'>受講プランのご案内</h2>
                        {
                            !token &&
                            <div className='warning-not-ticket pd-t-0 px-0 text-start'>＊お申込みの方は先に右上の「新規お申し込み」からアカウント登録をお願いします。</div>
                        }
                    </div>
                    <div className='content-price'>
                        <div className='container mg-t-20'>
                            <div className='row'>
                                {
                                    tickets?.length > 0 ?
                                        tickets.map(e => {
                                            const courseAmountTicket = e.courses?.length || 0;
                                            const freeAmountTicket = e.amount - courseAmountTicket;

                                            return (
                                                <div key={e.id} className='col-md-6 col-sm-9 col-lg-4 mg-t-50 ticket-card-container'>
                                                    <div className='item ticket-card'>
                                                        <h3><p className='title-ticket'>{e.name}</p> （{e.amount !== null ? <><FormatNumber value={e.amount}/>講座</> : '無制限'}）</h3>
                                                        <div className='nav-item'>
                                                            <p className='price price-ticket'>
                                                                <span><FormatNumber value={(e.price || 0) * 11 / 10}/>円</span>
                                                                <span className='note-price-ticket'>(税込)</span>
                                                            </p>
                                                            <div className='ticket-info-detail'>
                                                                {
                                                                    Boolean(freeAmountTicket) && (
                                                                        <p className='desc-1 desc-1-1'>
                                                                            <span className='desc-1-2'>
                                                                                <i className="fa-solid fa-star"></i> 
                                                                                <span className='desc-label-1-1'>自由選択単位数</span> 
                                                                            </span>
                                                                            <span><FormatNumber value={freeAmountTicket}/>単位</span>
                                                                        </p>
                                                                    )
                                                                }
                                                                {
                                                                    Boolean(courseAmountTicket) && (
                                                                        <p className='desc-1 desc-1-1'>
                                                                            <span className='desc-1-2'>
                                                                                <i className="fa-solid fa-star"></i> 
                                                                                <span className='desc-label-1-1'>指定講座数</span>
                                                                                <i 
                                                                                    className="fa-solid fa-circle-info ticket-course-info"
                                                                                    data-bs-toggle="tooltip" 
                                                                                    data-bs-placement="bottom" 
                                                                                    title="講座一覧を見るために、こちらをクリックしてください。"
                                                                                    onClick={() => onShowCourseListForTicketPlan(e)}
                                                                                />
                                                                            </span> 
                                                                            <span><FormatNumber value={courseAmountTicket}/>講座</span>
                                                                            
                                                                        </p>
                                                                    )
                                                                }
                                                                <p className='desc-1'>
                                                                    <span className='desc-1-2'>
                                                                        <i className="fa-solid fa-star"></i> 
                                                                        <span className='desc-label-1-1'>受講期間</span>    
                                                                    </span> 
                                                                    <span><FormatNumber value={e.duration}/>カ月</span>
                                                                </p>
                                                            </div>
                                                            <p className='desc-2 height-desc-2 text-justify-inter-word'>{e.description}</p>

                                                            {
                                                                e.allowBuy ? 
                                                                    <div role={'button'} className='read-more' onClick={()=> verifyCourseOfTicket(e)}><span>今すぐ申込む </span><img src='/images/ar1.png' alt='' /></div> : 
                                                                    <span className='small red' >※受講プランを追加購入する場合は、先に購入している プラン分を全て受講完了させてください。</span>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        :
                                        <div className='table-no-data w-100 mg-t-50'>
                                            <p>データはありません</p>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
})

export default TicketPlanListScreen;
