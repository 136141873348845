import { flow, makeObservable, observable } from 'mobx';
import BaseStore from './BaseStore';
import { InformationApi } from '../apis';

class InformationStore extends BaseStore {

    lstInformation = []
    information = null;
    pagingInformation = {
        ...this.paging,
        page: 1,
        size: 30
    }

    constructor(rootStore) {
        super();
        makeObservable(this, {
            lstInformation: observable,
            information: observable,
            pagingInformation: observable,
            getAllInformationsForStudent: flow.bound,
            getDetailInformation: flow.bound,
            viewInformation: flow.bound
        })
        this.rootStore = rootStore;
        this.api = new InformationApi();
    }

    *getAllInformationsForStudent(searchParams, isUpdatePaging = false) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getAllInformationsForStudent, searchParams);
            if(res?.ok) {
                this.lstInformation = res?.data?.elements || [];
                if (isUpdatePaging) {
                    this.setAttrObservable('pagingInformation', res?.data?.paginate, true, false);
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    *getDetailInformation(id, payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getDetailInformation, { id, payload });
            if(res?.ok) {
                this.information = res?.data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    *viewInformation(id, payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.viewInformation, { id, payload }, false, null, true);
            if(res?.ok) {
                return true;
            }
            return false;
        } catch (error) {
            console.log(error);
        }
    }

    clean() {
        super.clean();
        this.lstInformation = [];
        this.information = null;
    }
}

export default InformationStore;