import { observer } from 'mobx-react';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { DATE_TIME_YMD, SYSTEM_PATH } from '../../core/configs/constants';
import { useStore } from '../../core/utils/hook';
import QuestionItem from './components/QuestionItem';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import moment from 'moment';

const shuffleArray = array => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }

    return array;
}

function CompletionTestScreen() {
    const [questions, setQuestions] = useState([]);
    const [ theme, setTheme ] = useState(null);
    const [courseName, setCourseName] = useState('');
    const [isApplyCertificate, setIsApplyCertificate] = useState(false);
    const [isFinished, setIsFinished] = useState(false);
    const [showResult, setShowResult] = useState(false);
    const [percent, setPercent] = useState();
    const [messageError, setMessageError] = useState();

    const [surveyHistory, setSurveyHistory] = useState(false);
    const [surveyId, setSurveyId] = useState();
    const [endDate, setEndDate] = useState('');

    const params = useParams();
    const navigate = useNavigate();

    const {
        courseStore: { getListCompletionTest, achievement, setAttrObservable }
    } = useStore();

    const location = useLocation();
    const keepTestResultState = location?.state?.keepTestResultState ? JSON.parse(location.state.keepTestResultState) : null;

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async (isGetDataSurvey) => {
        try {
            const res = await getListCompletionTest({ courseId: params?.courseId });
            
            if(keepTestResultState) {
                setQuestions(keepTestResultState.questions);
                setCourseName(keepTestResultState.courseName);
                setIsApplyCertificate(keepTestResultState.isApplyCertificate || false);
            } else if(!isGetDataSurvey) {
                let questions = [];
                (res?.listCompletionTest || []).forEach((group) => {
                    let groupQuestions = JSON.parse(group?.completionTest);
                    if (groupQuestions.length > 0) {
                        groupQuestions = groupQuestions.map(x => {
                            return { ...x, lectureName: group.lectureName, lectureId: group.lectureId }
                        })
                        questions = [...questions, ...groupQuestions];
                    }
                });

                if(res?.theme) {
                    setTheme(res.theme);
                }

                setQuestions(shuffleArray(questions));
                setCourseName(res?.courseName);
                setIsApplyCertificate(res?.isApplyCertificate || false);
            }

            setSurveyHistory(res?.surveyHistory);
            setSurveyId(res?.surveyId);
            setEndDate(res?.endDate);
        } catch (error) {
            console.log('error: ', error);
        }
    };

    const handleChooseAnswer = (questionIndex, answerIdx) => {
        setQuestions((prev) => {
            return [...prev].map((item, index) => {
                if (questionIndex === index) {
                    const answers = [...(item.answers || [])].map((answer, i) => {
                        return { ...answer, isChecked: answerIdx === i };
                    });
                    return { ...item, answers };
                }
                return item;
            });
        });
    };

    const handleGoBack = () => {
        navigate(-1);
    };

    const handleSubmit = async() => {
        const answers = questions.filter((question) => {
            const userChoose = question.answers?.find((answer) => answer.isChecked && answer.is_correct);
            return !!userChoose;
        });
        const _percent = (answers.length / questions.length) * 100;
        const achievementNum = Math.ceil(_percent) > 100 ? 100 : Math.ceil(_percent);
        
        const testResultJson = JSON.stringify({
            courseName,
            achievement: achievementNum,
            doTestDate: new Date(),
            questions
        })

        try {
            const message = await achievement(
                params?.courseId,
                {
                    achievement: achievementNum,
                    testResultHistory: testResultJson
                }
            );

            // reload fetch data
            fetchData(true);

            setMessageError(message);
            setPercent(achievementNum);
            setShowResult(true);
            if (!message) {
                setIsFinished(true);
                setAttrObservable('keepTestResultState', {
                    percent: achievementNum,
                    showResult: true,
                    isFinished: true,
                    questions,
                    courseName,
                    isApplyCertificate
                })
            }
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }catch (error) {
            console.log('error: ', error);
        }

      
    };

    const handleGoCourseAttendance = async () => {
        // try {
        //     await achievement({ courseId: params?.courseId, achievement: percent });
        //     if (isApplyCertificate) {
        //         navigate(SYSTEM_PATH.COURSE + params?.courseId, { replace: true });
        //     } else {
        //         navigate(SYSTEM_PATH.ATTENDANCE_HISTORY);
        //     }
        // } catch (error) {
        //     console.log('error: ', error);
        // }
        if (percent < 70) {
            navigate(0);
        } else {
            navigate(SYSTEM_PATH.ATTENDANCE_HISTORY);
        }
    }

    const isDisabled = useMemo(() => {
        const answers = questions.filter((question) => {
            const userChoose = question.answers?.find((answer) => answer.isChecked);
            return !!userChoose;
        });
        return questions.length === 0 || questions.length !== answers.length;
    }, [questions]);

    if (questions.length === 0) return <div style={{ height: '68vh' }}></div>;

    const onClickSurveyBtn = () => {
        navigate(`/survey/${params?.courseId}`);
    }
    
    return (
        <div id="main" className="wrapper main-execute-completion main-execute-completion-2">
            <div className="bres-cus">
                <div className="container">
                    <ul>
                        <li>
                            <a href="/">
                                <img src="/images/icon-home.svg" alt="" />
                            </a>
                        </li>
                        {
                            theme && 
                            <>
                                <li className="dash">\</li>
                                <li>
                                    <a href={SYSTEM_PATH.THEMES + `?themeId=${theme?.themeId}`}>{theme?.themeName}</a>
                                </li>
                            </>
                        }
                        <li className="dash">\</li>
                        <li>
                            <a href={SYSTEM_PATH.COURSE + params?.courseId}>{courseName}</a>
                        </li>
                        <li className="dash">\</li>
                        <li>修了テスト</li>
                    </ul>
                </div>
            </div>
            <div className="content-execute-completion">
                <div className="container">
                    <h4 className="title-3">{courseName}</h4>
                    <div className='d-flex align-items-center justify-content-between'>
                        <div className="top-title-date">
                            <div className="row">
                                <div className="col-12">
                                    <h2 className="title-primary"> {!isFinished || !keepTestResultState?.isFinished ? '修了テスト': '修了テスト - 結果'}</h2>
                                </div>
                            </div> 
                        </div>

                        {
                            (percent !== undefined || keepTestResultState?.percent !== undefined) && !surveyHistory && endDate && surveyId && (
                                <div className='main-purchasse-history'>
                                    <div className='item-purchasse' style={{margin: '0', border: 'none'}}>
                                        <div className='nav-item-purchasse d-flex align-item-center justify-content-end flex-gap-20'>
                                            <div className='link-link'>
                                                <button 
                                                    onClick={() => onClickSurveyBtn()} 
                                                    className='survey_btn'>受講アンケートの回答 </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>

                    <div className="nav-execute-completion">
                        {(percent !== undefined || keepTestResultState?.percent !== undefined) && (
                            <div className="chart-map">
                                <div style={{ width: 200, height: 200, margin: 'auto' }}>
                                    <CircularProgressbar
                                        value={percent !== undefined ? percent : keepTestResultState?.percent } 
                                        maxValue={100} 
                                        text={`${percent !== undefined ? percent : keepTestResultState?.percent }%`}
                                        styles={buildStyles({ pathColor: '#FCB03B', textColor: '#FCB03B' })} />
                                </div>
                                <h3 className="title-2">正答率</h3>
                                <p className="desc">
                                    <span>実施日: </span>{moment().format(DATE_TIME_YMD)}
                                </p>
                            </div>
                        )}
                        {messageError && (
                            // eslint-disable-next-line max-len
                            <p className="red text-left msg-error text-center" dangerouslySetInnerHTML={{ __html: messageError.replace(/\n/g, '<br>') }} />
                        )}
                        {questions?.map((item, index) => {
                            return (
                                <QuestionItem
                                    isFinished={showResult || keepTestResultState?.showResult}
                                    disabled={showResult || keepTestResultState?.showResult}
                                    key={index} item={item} index={index} onChange={handleChooseAnswer} />
                            )
                        })}
                    </div>
                    
                    <div className="link-button">
                        {
                            percent === undefined && 
                            keepTestResultState?.percent === undefined && 
                            (!isFinished || !keepTestResultState?.isFinished) && 
                            <button type="button" onClick={handleGoBack} className="btn-1">キャンセル</button>
                        }
                        {
                            percent === undefined && keepTestResultState?.percent === undefined && 
                            <button disabled={isDisabled} type="button" onClick={handleSubmit} className="btn-2">
                                回答する
                            </button>
                        }
                        {
                            (percent !== undefined || keepTestResultState?.percent !== undefined) && 
                            <>
                                <button type="button" 
                                    onClick={() => navigate(SYSTEM_PATH.COURSE + params?.courseId, { replace: true })} 
                                    className="btn-1">
                                        講座に戻る
                                </button>
                                <button type="button" onClick={handleGoCourseAttendance} className="btn-2">
                                    {(percent) < 70 ? '再テストを実施する' : '受講履歴を確認する'}
                                </button>
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default observer(CompletionTestScreen);
