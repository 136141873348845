import { observer } from 'mobx-react';
import { useNavigate, useParams } from 'react-router-dom';
import { useStore } from '../../core/utils/hook';
import { useEffect, useMemo } from 'react';
import { DATE_YMD, INFORMATION_TYPE_MAPPER, SYSTEM_PATH } from '../../core/configs/constants';
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';
import { getFileNameFromUrl } from '../../core/utils/common';

import './styles.scss';
import { flowResult } from 'mobx';

const MAX_INFORMATION_RELATIVE = 10;

const InformationDetail = observer(() => {
    // other 
    const { id } = useParams();
    const navigate = useNavigate();

    // store
    const { 
        informationStore: { lstInformation, getAllInformationsForStudent, information, getDetailInformation, clean } 
    } = useStore();

    const informationTypeMapper = useMemo(() => {
        if(information) {
            return INFORMATION_TYPE_MAPPER[information.type]
        }
    }, [information])

    const lstInformationRelative = useMemo(() => {
        if (!lstInformation) return [];
        const isCurrentInforContainedInLst = lstInformation.findIndex(information => information.id === Number(id));
        if (isCurrentInforContainedInLst === -1) {
            return lstInformation.filter((information, index) => information.id !== Number(id) && index !== lstInformation?.length-1);
        }
        return lstInformation.filter(information => information.id !== Number(id));
    }, [lstInformation])

    // lifecycle
    useEffect(() => {
        onFetchData();
        return () => {
            clean();
        }
    }, [id])

    useEffect(() => {
        if(information) {
            getAllInformationsForStudent({
                type: information.type,
                size: MAX_INFORMATION_RELATIVE + 1
            })
        }
    }, [information])

    // function
    const onFetchData = () => {
        getDetailInformation(id);
    }

    return (
        <div
            id="main"
            className="wrapper main-account-trgisstration-1 main-account-trgisstration-2 main-account-trgisstration-4 main-update main-send-inquiry"
        >
            <div className="bres-cus">
                <div className="container">
                    <ul>
                        <li className='flex-none'>
                            <a href="/"><img src="/images/icon-home.svg" alt="" /></a> 
                        </li>
                        <li className="dash">\</li>
                        <li className='flex-none'>
                            <a href={SYSTEM_PATH.INFORMATION_SCREEN}>一覧をみる</a>
                        </li>
                        <li className="dash">\</li>
                        <li className='line-clamp-1'>{information?.title}</li>
                    </ul>
                </div>
            </div>
            <div className='information-screen-detail container'>
                <div className='information-type d-flex align-items-center flex-gap-10'>
                    <div
                        style={{ backgroundColor: informationTypeMapper?.BG_ICON }} 
                        className='information-type-icon d-flex align-items-center flex-gap-5'>
                        <img
                            src={informationTypeMapper?.ICON}
                            style={{ color: informationTypeMapper?.COLORCLASS }} />
                        <span style={{ color: informationTypeMapper?.COLORCLASS }}>{informationTypeMapper?.LABEL}</span>
                    </div>
                    <div className='information-type-public-date d-flex align-items-center flex-gap-5'>
                        <i className='fa-regular fa-calendar'></i>
                        <span>{information?.public_date ? moment(information.public_date).format(DATE_YMD) : ''}</span>
                    </div>
                </div>
                <div className='title'>{information?.title}</div>
                <div className='content'>{ReactHtmlParser(information?.content ?? '')}</div>
                <div className="back-to-list-btn" onClick={() => navigate(SYSTEM_PATH.INFORMATION_SCREEN)}>
                    <span>一覧へ</span>
                </div>
                {
                    information && information?.docs?.length > 0 && (
                        <div className='informtation-docs'>
                            <div className='informtation-docs-title'>添付ファイル</div>
                            <div className='informtation-docs-content'>
                                {
                                    information.docs.map((doc, index) => (
                                        <div key={index} className='informtation-docs-item d-flex flex-gap-5'>
                                            <i className='fa-regular fa-file-lines'></i>
                                            <a
                                                href={doc} 
                                                className='line-clamp-1'
                                                download target="_blank" 
                                                rel="noopener noreferrer">{getFileNameFromUrl(doc)}</a>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    )
                }
                {
                    lstInformationRelative.length > 0 && (
                        <div className='relative-information'>
                            <div className='relative-information-title'>{`${INFORMATION_TYPE_MAPPER[information.type].LABEL}一覧`}</div>
                            <div className='relative-information-content'>
                                {
                                    lstInformationRelative.map((information, index) => (
                                        <RelativeInformationItem key={index} data={information} />
                                    ))
                                }
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )
});

export default InformationDetail;

const RelativeInformationItem = ({ data }) => {
    // other
    const navigate = useNavigate();

    // store
    const { informationStore: { viewInformation } } = useStore();

    // function
    const onClickViewDetail = async () => {
        const res =  await flowResult(viewInformation(data.id, {}));
        if (res) {
            navigate(`${SYSTEM_PATH.INFORMATION_SCREEN}/${data.id}`);
        }
    }

    return (
        <div className='relative-information-item d-flex align-items-center flex-gap-10'>
            <div className='relative-information-item-calendar d-flex align-items-center flex-gap-5'>
                <i className='fa-regular fa-calendar'></i>
                <span>{data?.publicDate ? moment(data.publicDate).format(DATE_YMD) : ''}</span>
            </div>
            <span className='line-clamp-1 cursor-pointer' onClick={onClickViewDetail}>{data.title}</span>
        </div>
    )
}