import { yupResolver } from '@hookform/resolvers/yup';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import yup from '../../core/utils/yupValidate';
import { useNavigate, useSearchParams } from 'react-router-dom';
import NotFoundScreen from '../404/NotFoundScreen';
import { LOGIN_TYPE, MSG, SYSTEM_PATH, USER_TYPE } from '../../core/configs/constants';
import { useStore } from '../../core/utils/hook';
import './style.scss';

const SetPasswordScreen = observer((props) => {
    const {
        authStore: { clearAuthenticationWithSetPassword },
        passwordStore: { checkUuid, setPassword }
    } = useStore();

    const [noUuid, setNoUuid] = useState(false);
    const [loading, setLoading] = useState(true);
    const [showPassword, setShowPassword] = useState({
        password: false,
        passwordConfirm: false
    });
    const [userRole, setUserRole] = useState(LOGIN_TYPE.STUDENT);

    const [searchParams] = useSearchParams();
    const uuid = searchParams.get('uuid');
    const isForgetPassword = searchParams.get('isForgetPassword');
    const userType = searchParams.get('userType');

    const validateSetPasswordSchema = yup.object().shape({
        password: yup
            .string()
            .required(MSG['error.required'])
            .matches(
                new RegExp('^(?=.*[A-Z])[a-zA-Z0-9]{8,}$'),
                MSG['error.password_format_detail']
            ),
        passwordConfirm: yup
            .string()
            .oneOf([yup.ref('password')], MSG['error.confirm_password_not_match'])
            .required(MSG['error.required'])
    });

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
        watch,
        trigger
    } = useForm({
        resolver: yupResolver(validateSetPasswordSchema),
        mode: 'onChange'
    });

    const password = watch('password', '');
    const passwordConfirm = watch('passwordConfirm', '');

    const navigate = useNavigate();

    const toggleShowPassword = (state) => {
        setShowPassword({
            ...showPassword,
            [state]: !showPassword[state]
        });
    };

    React.useEffect(() => {
        clearAuthenticationWithSetPassword();
    }, [])

    React.useEffect(() => {
        if (password?.length > 0 &&  passwordConfirm!== '') {
            trigger('passwordConfirm');
        }
    }, [password])

    const onSetPass = async (data) => {
        const res = await setPassword({ ...data, uuid, isForgetPassword : !!isForgetPassword });
        if (res) {
            navigate(SYSTEM_PATH.LOGIN);
        }
    };

    React.useEffect(() => {
        handleCheckUuid();
    }, []);

    const handleCheckUuid = async () => {
        try {
            const res = await checkUuid({ uuid });
            if (!res) {
                setNoUuid(true);
            } else {
                if (res?.role === USER_TYPE.GROUP_MANAGER) {
                    setUserRole(LOGIN_TYPE.GROUP_ADMIN)
                } else {
                    setUserRole(LOGIN_TYPE.STUDENT)
                }
            }
            setLoading(false);
        } catch (error) {
            console.log('error: ', error);
            setNoUuid(true);
            setLoading(false);
        }
    };
    
    if (loading) return null;

    if (!uuid || noUuid) return (
        <div style={{ margin: '50px auto', textAlign: 'center' }}>
            <NotFoundScreen/>
        </div>
    )
    
    if(userType != USER_TYPE.GROUP_MANAGER) {

        return (
            <div id="main" className="wrapper main-signin main-ChangPassword">
                <div className="bres-cus">
                    <div className="container">
                        <ul>
                            <li  >
                                <a href={SYSTEM_PATH.HOME}>
                                    <img src="/images/icon-home.svg" alt="" />
                                </a>
                            </li>
                            <li className="dash">\</li>
                            <li>パスワード変更</li>
                        </ul>
                    </div>
                </div>
            
                <div className="content-signin">
                    <div className="container">
                        <div className="content-form">
                            <h2 className="title-primary" style={{ lineHeight: '40px' }}>パスワード変更</h2>
                            <h3
                                className="title-primary mg-b-10"
                                style={{ fontSize: '24px', fontWeight: '500', marginTop: '4px', lineHeight: 'inherit' }}>{
                                    userRole === LOGIN_TYPE.GROUP_ADMIN ? '（団体管理者向け）' : '（受講者向け）'
                                }</h3>
                            <p className="title-primary-setPass">下記へ新しいパスワードを入力してください </p>

                            <form onSubmit={handleSubmit(onSetPass)}>
                                <div className="item">
                                    <label htmlFor="">
                                新しいパスワード: <span>*</span>
                                    </label>
                                    <div className="input">
                                        <input
                                            id="password"
                                            type={!showPassword.password ? 'password' : 'text'}
                                            {...register('password')}
                                            autoComplete="off"
                                            onCopy={(e) => e.preventDefault()}
                                            onPaste={(e) => e.preventDefault()}
                                            onCut={(e) => e.preventDefault()}
                                        />
                                        <button
                                            type="button"
                                            onClick={() => toggleShowPassword('password')}
                                        >
                                            {!showPassword.password ? (
                                                <i className="fa fa-eye-slash"></i>
                                            ) : (
                                                <i className="fa fa-eye"></i>
                                            )}
                                        </button>
                                    </div>
                                    {errors?.password && (
                                        <div className="text-danger mg-t-5">
                                            {errors.password?.message}
                                        </div>
                                    )}
                                </div>
                                <div className="item">
                                    <label htmlFor="passwordConfirm">
                  新しいパスワード確認: <span>*</span>
                                    </label>
                                    <div className="input">
                                        <input
                                            id="passwordConfirm"
                                            type={!showPassword.passwordConfirm ? 'password' : 'text'}
                                            {...register('passwordConfirm')}
                                            autoComplete="off"
                                            onCopy={(e) => e.preventDefault()}
                                            onPaste={(e) => e.preventDefault()}
                                            onCut={(e) => e.preventDefault()}
                                        />
                                        <button
                                            type="button"
                                            onClick={() => toggleShowPassword('passwordConfirm')}
                                        >
                                            {!showPassword.passwordConfirm ? (
                                                <i className="fa fa-eye-slash"></i>
                                            ) : (
                                                <i className="fa fa-eye"></i>
                                            )}
                                        </button>
                                    </div>
                                    {errors?.passwordConfirm && (
                                        <div className="text-danger mg-t-5">
                                            {errors.passwordConfirm?.message}
                                        </div>
                                    )}
                                </div>
                                <input type="submit" value="送信・ログインに進む" />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return(
        <div className='set-password-ga-screen'>
            <div className='set-password-ga-form shadow-box pd-lr-40'>
                <div className='title fs-heading-large fw-bolder text-center'>パスワード設定</div>
                <div className='container-note-set-password mg-t-20'>
                    <div className='text-danger fw-bolder'>団体管理者としての登録が完了しました。<br/>パスワードを設定のうえご利用ください。</div>
                    <div className='warning mg-t-30'>
                        <div className='fw-bolder'>パスワードには次を含める必要があります</div>
                        <div className='mg-t-5'>８文字以上の半角英数字（１つ以上の大文字含める）</div>
                    </div>
                </div>
                <div className='container-form mg-t-30'>
                    <form onSubmit={handleSubmit(onSetPass)}>
                        <div>
                            <label htmlFor='password' className='field-required fw-bold'>設定するパスワード</label>
                            <div className='w-100'>
                                <input {...register('password')} id='password' type={!showPassword.password ? 'password' : 'text'} 
                                    className='mg-t-10 col-12' autoComplete='off'
                                    onCopy={(e) =>  e.preventDefault()}  
                                    onPaste={(e) => e.preventDefault()}  
                                    onCut={(e) =>   e.preventDefault()}
                                />
                                <button type='button' className='btn-icon-input' onClick={() => toggleShowPassword('password')}>
                                    {
                                        showPassword.password ? 
                                            <i className='fas fa-eye'></i>                                
                                            :
                                            <i className='fas fa-eye-slash'></i>
                                    }
                                </button>
                                {
                                    errors?.password &&
                                        <div className='text-danger mg-t-5 fs-error'>{errors.password?.message}</div>
                                }
                            </div>
                        </div>
                        <div className='mg-t-20'>
                            <label htmlFor='passwordConfirm' className='field-required fw-bold'>パスワードの確認</label>
                            <div className='w-100'>
                                <input {...register('passwordConfirm')} id='passwordConfirm' 
                                    type={!showPassword.passwordConfirm ? 'password' : 'text'} 
                                    className='mg-t-10 col-12' autoComplete='off'
                                    onCopy={(e) =>  e.preventDefault()}  
                                    onPaste={(e) => e.preventDefault()}  
                                    onCut={(e) =>   e.preventDefault()}
                                />
                                <button type='button' className='btn-icon-input' onClick={() => toggleShowPassword('passwordConfirm')}>
                                    {
                                        showPassword.passwordConfirm ? 
                                            <i className='fas fa-eye'></i>                                
                                            :
                                            <i className='fas fa-eye-slash'></i>
                                    }
                                </button>
                                {
                                    errors?.passwordConfirm &&
                                        <div className='text-danger mg-t-5 fs-error'>{errors.passwordConfirm?.message}</div>
                                }
                            </div>
                        </div>
                        <div className='mg-t-30 text-center'>
                            <button type='submit' className='btn btn-default-2 width-200' disabled={isSubmitting}>パスワード設定</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
});

export default SetPasswordScreen;
