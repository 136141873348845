import classNames from 'classnames';
import TreeView, { flattenTree } from 'react-accessible-treeview';
import './style.scss';

const TreeViewCustom = (props => {

    const { 
        className, 
        elementClass,
        data,
        onSelect, 
        onNodeSelect,
        onExpand,
        onHandLeExpand,
        onClickTreeName,
        multiSelect = false,
        propagateSelect = false,
        propagateSelectUpwards = false,
        propagateCollapse = true,
        expandOnKeyboardSelect = false,
        togglableSelect = false,
        defaultSelectedIds = [],
        defaultExpandedIds = [],
        selectedIds = [],
        expandedIds = [],
        clickAction = 'SELECT',
        onBlur,
        isCheckboxSelected = false,
        checkedIds = [],
        onCheckedBox
    } = props;
    
    return (
        <TreeView
            className={classNames('tree-view-custom', className)}
            data={flattenTree(data)}
            onSelect={onSelect}
            onNodeSelect={onNodeSelect}
            onExpand={onExpand}
            multiSelect={multiSelect}
            propagateSelect={propagateSelect}
            propagateSelectUpwards={propagateSelectUpwards}
            propagateCollapse={propagateCollapse}
            expandOnKeyboardSelect={expandOnKeyboardSelect}
            togglableSelect={togglableSelect}
            defaultSelectedIds={defaultSelectedIds}
            defaultExpandedIds={defaultExpandedIds}
            selectedIds={selectedIds}
            expandedIds={expandedIds}
            clickAction={clickAction}
            onBlur={onBlur}
            nodeRenderer={({
                element,
                isBranch,
                isExpanded,
                isDisabled,
                getNodeProps,
                level,
                handleExpand
            }) => {
                const nodeProps = getNodeProps({ onClick: () => {} });
                return (
                    <div
                        {...nodeProps}
                        style={{
                            paddingLeft: level === 1 ? 10 : 10 + 25 * (level - 1),
                            opacity: isDisabled ? 0.5 : 1
                        }}
                        className={classNames(nodeProps.className, element.metadata.className ?? '', 'd-flex')}
                    >   
                        <ArrowIcon
                            isOpen={isExpanded}
                            onHandleExpand={() => onHandLeExpand ? onHandLeExpand(element) : handleExpand}
                            iconUp={isCheckboxSelected ? 'fa-chevron-right' : 'fa-caret-up'}
                            iconDown={isCheckboxSelected ? 'fa-chevron-down' : 'fa-caret-down'}
                            className={classNames(isCheckboxSelected && 'd-flex justify-content-center align-items-center opacity-50')}
                            hidden={!isBranch}
                            animationHover={!isCheckboxSelected && isBranch}
                        />
                        {isCheckboxSelected && 
                            <input
                                type='checkbox'
                                className='mg-r-10 cursor-pointer'
                                onChange={(event) => onCheckedBox(event, element)}
                                checked={checkedIds.includes(element.id)}/>
                        }
                        <span 
                            className={classNames('tree-name', 
                                (!isCheckboxSelected && element?.children?.length > 0)
                                    || (isCheckboxSelected && element.id === 1) && 'fw-bold cursor-pointer', 
                                element?.metadata?.hasClick && 'has-click', elementClass && elementClass(element))} 
                            onClick={onClickTreeName ? () => onClickTreeName(element) : () => {}}>
                            {element.name}
                        </span>
                    </div>
                );
            }}
        />
    )
})

const ArrowIcon = ({
    isOpen, className, onHandleExpand, iconUp = 'fa-caret-up', iconDown = 'fa-caret-down', hidden = false, animationHover = true }) => {
    return <i className={classNames(
        'pd-r-10 cursor-pointer',
        animationHover && 'tree-icon',
        isOpen ? `fa-solid ${iconUp}` : `fa-solid ${iconDown}`,
        className,
        hidden && 'text-transparent'
    )} style={{'color': '#1A2C44' }} onClick={onHandleExpand}/>;
};

export default TreeViewCustom;