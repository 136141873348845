import ApiStore from './ApiStore';
import AuthStore from './AuthStore';
import CourseStore from './CourseStore';
import InquiryStore from './InquiryStore';
import ModalStore from './ModalStore';
import ThemeStore from './ThemeStore';
import UserStore from './UserStore';
import MessageStore from './MessageStore';
import LectureStore from './LectureStore';
import TicketStore from './TicketStore';
import GeneralSettingStore from './GeneralSettingStore';
import GroupAdminStore from './GroupAdminStore';
import PaymentStore from './PaymentStore';
import CreditStore from './CreditStore';
import NotifyStore from './NotifyStore';
import PasswordStore from './PasswordStore';
import InformationStore from './InformationStore';
class RootStore {

    constructor() {
        this.modalStore = new ModalStore(this);
        this.apiStore = new ApiStore(this);
        this.authStore = new AuthStore(this);
        this.userStore = new UserStore(this);
        this.passwordStore = new PasswordStore(this);
        this.inquiryStore = new InquiryStore(this);
        this.themeStore = new ThemeStore(this);
        this.courseStore = new CourseStore(this);
        this.messageStore = new MessageStore(this);
        this.lectureStore = new LectureStore(this);
        this.ticketStore = new TicketStore(this);
        this.generalSettingStore = new GeneralSettingStore(this);
        this.groupAdminStore = new GroupAdminStore(this);
        this.paymentStore = new PaymentStore(this);
        this.creditStore = new CreditStore(this);
        this.notifyStore = new NotifyStore(this);
        this.informationStore = new InformationStore(this);
    }
}

export default RootStore;